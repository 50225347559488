export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const SET_FEATURED_VIDEOS = "SET_FEATURED_VIDEOS";
export const SET_VIDEOS = "SET_VIDEOS";
export const SET_EXTERNAL_VALUE = "SET_EXTERNAL_VALUE";
export const SET_ANALYTICS_VALUE = "SET_ANALYTICS_VALUE";
export const SET_LOADING_VIDEOS = "SET_LOADING_VIDEOS";
export const SET_SHOW_BANNER = "SET_SHOW_BANNER";
export const SET_BANNERS = "SET_BANNERS";
export const SET_IDEAS = "SET_IDEAS";
export const SET_TOOLS = "SET_TOOLS";
export const SET_LOADING_CONTENT = "SET_LOADING_CONTENT";
export const UPDATE_SITUATION_CARDS = "UPDATE_SITUATION_CARDS";
export const SET_LOADER = "SET_LOADER";
export const SET_NORMING_GAME = "SET_NORMING_GAME";

export function setActivePage(payload) {
  return { type: SET_ACTIVE_PAGE, payload };
}

export function setFeaturedVideos(payload) {
  return { type: SET_FEATURED_VIDEOS, payload };
}

export function setVideos(payload) {
  return { type: SET_VIDEOS, payload };
}

export function setExternalValue(payload) {
  return { type: SET_EXTERNAL_VALUE, payload };
}

export function setAnalyticsValue(payload) {
  return { type: SET_ANALYTICS_VALUE, payload };
}

export function setLoadingVideos(payload) {
  return { type: SET_LOADING_VIDEOS, payload };
}

export function setLoadingContent(payload) {
  return { type: SET_LOADING_CONTENT, payload };
}

export function setShowBanner(payload) {
  return { type: SET_SHOW_BANNER, payload };
}

export function setIdeas(payload) {
  return { type: SET_IDEAS, payload };
}

export function setBanners(payload) {
  return { type: SET_BANNERS, payload };
}

export function setLoader(payload) {
  return { type: SET_LOADER, payload };
}

export function setTools(payload) {
  return { type: SET_TOOLS, payload };
}

export function setNormingGame(payload) {
  return { type: SET_NORMING_GAME, payload };
}
