import { getVideoList } from "./firebase-functions";
import dayjs from "dayjs";

export const combineItems = (videos = [], blog) => {
  let itemsList = [];

  videos.forEach((item) => {
    const summary = item.description
      .slice(0, item.description.length > 200 ? 200 : item.description.length)
      .concat("...");
    itemsList.push({
      type: "video",
      thumbnail: item.thumbnails.medium,
      title: item.title,
      description: item.description,
      summary: summary,
      image: item.thumbnails.standard,
      videoId: item.id,
      url: "",
      date: dayjs(item.published),
    });
  });

  blog.forEach((item) => {
    itemsList.push({
      type: "blog",
      thumbnail: item.thumbnail,
      title: item.ideaTitle,
      description: item.ideaContent,
      summary: item.ideaSummary,
      image: item.thumbnail,
      videoId: "",
      url: item.thumbnail,
      date: dayjs(item.published),
      postId: item.slug,
    });
  });
  return itemsList.slice().sort((a, b) => {
    if (a.date.isBefore(b.date)) return 1;
    if (b.date.isBefore(a.date)) return -1;
    return 0;
  });
};

export const getVideos = (playlistId) => {
  return getVideoList({ playlistId: playlistId })
    .then((result) => {
      const playlist = result.data.items;
      let videos = [];
      playlist.forEach((item) => {
        const video = {
          id: item.snippet.resourceId.videoId,
          title: item.snippet.title,
          description: item.snippet.description,
          thumbnails: {
            default: item.snippet.thumbnails.default.url,
            medium: item.snippet.thumbnails.medium.url,
            high: item.snippet.thumbnails.high.url,
            standard: item.snippet.thumbnails.standard.url,
          },
          published: item.contentDetails.videoPublishedAt,
        };
        videos.push(video);
      });
      return videos;
    })
    .catch((error) => {
      return [];
    });
};

export const getVideo = (videoId) => {
  return getVideoList({ videoId: videoId })
    .then((result) => {
      const video = result.data.items;
      let videos = [];
      video.forEach((item) => {
        const video = {
          id: videoId,
          title: item.snippet.title,
          description: item.snippet.description,
          thumbnails: {
            default: item.snippet.thumbnails.default.url,
            medium: item.snippet.thumbnails.medium.url,
            high: item.snippet.thumbnails.high.url,
            standard: item.snippet.thumbnails.standard.url,
          },
        };
        videos.push(video);
      });
      return videos;
    })
    .catch((error) => {
      return [];
    });
};
