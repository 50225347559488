import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Grid, Header, Menu, Loader } from "semantic-ui-react";
import MyCard from "../components/MyCard";
import { combineItems } from "../util/video-utility";
import { setVideos } from "../actions/index";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter);

const buildPosts = (videos, ideas, displayType) => {
  const currentIdeas = ideas.filter((idea) =>
    dayjs().isSameOrAfter(idea.published)
  );
  const allIdeas = combineItems(videos, currentIdeas);
  const filteredPosts = allIdeas.filter((post) => {
    return displayType === "all" || displayType === post.type;
  });

  return filteredPosts.map((post, index) => {
    return (
      <Grid.Column key={index}>
        <MyCard
          type={post.type}
          thumbnail={post.thumbnail}
          title={post.title}
          summary={post.summary}
          videoId={post.videoId}
          postId={post.postId}
        />
      </Grid.Column>
    );
  });
};

const Ideas = (props) => {
  const [ideasDisplayType, setIdeasDisplayType] = useState("all");

  return (
    <div>
      <Helmet>
        <title>Bright Ideas! - My Job Is Fun</title>
        <meta
          name="description"
          content="Blogs and videos sharing our insights, triumphs (and a few failures) from our experience as practitioners working for some of the world's most recognized brands."
        />

        <meta name="og:url" content={window.location.href} />
        <meta name="og:title" content="Bright Ideas!" />
        <meta
          name="og:description"
          content="Blogs and videos sharing our insights, triumphs (and a few failures) from our experience as practitioners working for some of the world's most recognized brands."
        />
        <meta name="og:type" content="website" />
        {/* Twitter Card tags */}
        <meta name="twitter:title" content="Bright Ideas!" />
        <meta
          name="twitter:description"
          content="Blogs and videos sharing our insights, triumphs (and a few failures) from our experience as practitioners working for some of the world's most recognized brands."
        />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <ResponsiveContainer includeHomepageHeading={false}>
        <Grid container stackable className="ideas">
          <Grid.Row centered>
            <Header as="h2" textAlign="center">
              Bright Ideas
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Menu pointing secondary>
              <a
                href="all"
                onClick={(event) => {
                  event.preventDefault();
                  setIdeasDisplayType("all");
                }}
                className={ideasDisplayType === "all" ? "active item" : "item"}
              >
                All
              </a>
              <a
                href="blog"
                onClick={(event) => {
                  event.preventDefault();
                  setIdeasDisplayType("blog");
                }}
                className={ideasDisplayType === "blog" ? "active item" : "item"}
              >
                Blogs
              </a>
              <a
                href="video"
                onClick={(event) => {
                  event.preventDefault();
                  setIdeasDisplayType("video");
                }}
                className={
                  ideasDisplayType === "video" ? "active item" : "item"
                }
              >
                Videos
              </a>
            </Menu>
          </Grid.Row>
          <Grid.Row
            columns={props.windowWidth < 979 ? 2 : 3}
            className="ideas-row"
          >
            {props.loadingVideos ? (
              <Loader active inline="centered" size="huge" />
            ) : (
              buildPosts(props.videos, props.ideas, ideasDisplayType)
            )}
          </Grid.Row>
        </Grid>
      </ResponsiveContainer>
    </div>
  );
};

Ideas.propTypes = {
  windowWidth: PropTypes.number.isRequired,
  videoList: PropTypes.array,
};

const mapStateToProps = (state) => ({
  videos: state.videos,
  loadingVideos: state.loadingVideos,
  ideas: state.ideas,
});

const mapDispatchToProps = (dispatch) => ({
  setVideos: (videos) => dispatch(setVideos(videos)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ideas);
