import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Grid, Header, Image, Icon, Loader } from "semantic-ui-react";
import history from "../components/history";
import dayjs from "dayjs";

const buildIdea = (blogPost) => {
  if (blogPost.length !== 1) {
    return (
      <Header as="h2" textAlign="center">
        Idea Not Found
      </Header>
    );
  } else {
    const post = blogPost[0];
    return (
      <Grid container stackable className="idea">
        <Grid.Row centered>
          <Header as="h2" textAlign="center">
            {post.ideaTitle}
          </Header>
        </Grid.Row>
        <Grid.Row centered className="idea-authored">
          {post.author} | {dayjs(post.published).format("MM/DD/YYYY")}
        </Grid.Row>
        <Grid.Row className="idea-image">
          <Image src={post.thumbnail} size="big" centered />
        </Grid.Row>
        <Grid.Row className="idea-content">
          <div dangerouslySetInnerHTML={{ __html: post.ideaContent }}></div>{" "}
        </Grid.Row>
        <Grid.Row>
          <a href="/ideas" onClick={handleClick}>
            <Icon name="arrow left"></Icon> Back
          </a>
        </Grid.Row>
      </Grid>
    );
  }
};

const buildMetaData = (blogPost) => {
  if (blogPost.length !== 1) {
    return (
      <Helmet>
        <title>Idea Not Found - My Job Is Fun</title>
      </Helmet>
    );
  } else {
    const post = blogPost[0];
    return (
      <Helmet>
        <title>{post.ideaTitle} - My Job Is Fun</title>
        <meta name="description" content={post.ideaSummary} />
        <meta name="og:url" content={window.location.href} />
        <meta name="og:title" content={post.ideaTitle} />
        <meta name="og:description" content={post.ideaSummary} />
        <meta name="og:type" content="website" />
        {/* Twitter Card tags */}
        <meta name="twitter:title" content={post.ideaTitle} />
        <meta name="twitter:description" content={post.ideaSummary} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
    );
  }
};

const getIdea = (ideas, slug) => {
  return ideas.filter((idea) => {
    return idea.slug === slug;
  });
};

const handleClick = (e) => {
  e.preventDefault();
  history.push("/ideas");
};

const Blog = (props) => {
  const pageIdea = getIdea(props.ideas, props.location.search.substring(3));

  return (
    <div>
      {buildMetaData(pageIdea)}
      <ResponsiveContainer includeHomepageHeading={false}>
        {props.loadingContent ? (
          <Loader active inline="centered" size="huge" />
        ) : (
          buildIdea(pageIdea)
        )}
      </ResponsiveContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ideas: state.ideas,
  loadingContent: state.loadingContent,
});

export default connect(mapStateToProps)(Blog);
