import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Grid, Image, Header, Button } from "semantic-ui-react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter);

class Tools extends React.Component {
  state = {};

  handleClick = (slug, slugType) => {
    switch (slugType) {
      case "mjif":
        window.location.assign(`https://myjobisfun.com/${slug}`);
        break;
      case "podia":
        window.location.assign(`https://courses.myjobisfun.com/${slug}`);
        break;
    }
  };

  createTools = () => {
    const isWidthLessThan979 = this.props.windowWidth < 979;
    const currentTools = this.props.tools
      .slice()
      .filter((tool) => dayjs().isSameOrAfter(tool.effective));
    return currentTools.map((tool, index) => {
      return (
        <Grid.Row stretched className="product" key={index}>
          {!isWidthLessThan979 && <Grid.Column width={1}></Grid.Column>}
          <Grid.Column width={isWidthLessThan979 ? 8 : 7}>
            <Image src={tool.image} />
          </Grid.Column>
          <Grid.Column width={isWidthLessThan979 ? 8 : 7}>
            <Header as="h3">{tool.name}</Header>
            <p>{tool.description}</p>
            <Header as="h2">{tool.cost}</Header>
            <Button
              basic
              color="violet"
              onClick={() => this.handleClick(tool.slug, tool.slugType)}
            >
              View tool
            </Button>
          </Grid.Column>
          {!isWidthLessThan979 && <Grid.Column width={1}></Grid.Column>}
        </Grid.Row>
      );
    });
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Tools - My Job Is Fun</title>
          <meta
            name="description"
            content="Ever feel like you’re trying to reinvent the wheel? We’ve assembled some of our favorite checklists, assessments, and quickstart guides to help you leap into action to support a dazzling culture."
          />
          <meta name="og:url" content={window.location.href} />
          <meta name="og:title" content="Tools" />
          <meta
            name="og:description"
            content="Ever feel like you’re trying to reinvent the wheel? We’ve assembled some of our favorite checklists, assessments, and quickstart guides to help you leap into action to support a dazzling culture."
          />
          <meta name="og:type" content="website" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Tools" />
          <meta
            name="twitter:description"
            content="Ever feel like you’re trying to reinvent the wheel? We’ve assembled some of our favorite checklists, assessments, and quickstart guides to help you leap into action to support a dazzling culture."
          />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <ResponsiveContainer includeHomepageHeading={false}>
          <Grid container stackable className="tools">
            <Grid.Row centered>
              <Header as="h2" textAlign="center">
                Tools
              </Header>
            </Grid.Row>
            {this.createTools()}
          </Grid>
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tools: state.tools,
});

export default connect(mapStateToProps)(Tools);
