import React, { useState } from "react";
import styles from "./card.css";
import { Image } from "semantic-ui-react";

const Card = (props) => {
  const { type, text, color, flipped } = props;

  return (
    <div className="wrapper">
      <div className="card">
        <div className={`card-side front ${flipped} ${color}`}>
          <div className="content">
            <h2>{type}</h2>
          </div>
          <div className={"front-image"}>
            {type === "Situation" && (
              <Image src="../../images/noun_Meeting_1141040.png" />
            )}
            {type === "Characteristic" && (
              <Image src="../../images/noun_FAQ_1140958.png" />
            )}
          </div>
        </div>
        <div className={`card-side back ${flipped} ${color}`}>
          <div className="content">
            <h2>{type}</h2>
            <h3>{text}</h3>
          </div>
          <div className={"back-image"}>
            <Image src="../../images/MJIF_logo.png" />
          </div>
        </div>
      </div>
      {/* <a href="#" onClick={handleClick}>
        Flip
      </a> */}
    </div>
  );
};

export default Card;
