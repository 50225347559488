import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import HomepageHeading from "./HomepageHeading";
import Footer from "./Footer";
import Banner from "./Banner";
import history from "./history";

import {
  Button,
  Container,
  Menu,
  Responsive,
  Segment,
  Visibility,
  Image,
} from "semantic-ui-react";

const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  navigateToLogin = () => {
    window.location.assign("https://courses.myjobisfun.com/login");
  };

  navigateHome = (e) => {
    e.preventDefault();
    history.push("/");
  };

  render() {
    const { children, includeHomepageHeading } = this.props;
    const { fixed } = this.state;
    return (
      <div>
        <Responsive
          getWidth={getWidth}
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <Banner desktop={true} />
          <Visibility
            once={false}
            onBottomPassed={this.showFixedMenu}
            onBottomPassedReverse={this.hideFixedMenu}
          >
            <Segment
              textAlign="center"
              style={
                includeHomepageHeading
                  ? {
                      padding: "0em 0em 3em",
                      backgroundImage: "url(/images/desktop_main.jpg)",
                      backgroundPosition: "left",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }
                  : { padding: "0em 0em" }
              }
              vertical
            >
              <div role="navigation">
                <Menu
                  fixed={fixed ? "top" : null}
                  pointing={true}
                  secondary={true}
                  size="large"
                  className="desktop-menu"
                >
                  <Container>
                    <a href="/" onClick={this.navigateHome}>
                      <Image
                        src="/images/MJIF_logo.png"
                        size="tiny"
                        centered
                        alt="My Job is Fun logo"
                      />
                    </a>
                    <Menu.Item
                      as={NavLink}
                      exact
                      to="/"
                      content="Home"
                      position="right"
                    />
                    <Menu.Item as={NavLink} to="/courses" content="Courses" />
                    <Menu.Item as={NavLink} to="/tools" content="Tools" />
                    <Menu.Item as={NavLink} to="/ideas" content="Ideas" />
                    <Menu.Item as={NavLink} to="/about" content="About Us" />
                    <Menu.Item
                      as={NavLink}
                      to="/contact"
                      content="Contact Us"
                    />
                    <div className="item">
                      <Button
                        basic
                        color="violet"
                        onClick={this.navigateToLogin}
                      >
                        Log in
                      </Button>
                    </div>
                  </Container>
                </Menu>
              </div>
              {includeHomepageHeading && <HomepageHeading />}
            </Segment>
          </Visibility>
          <div style={{ marginRight: "2em", marginLeft: "2em" }}>
            {children}
          </div>
          <Footer />
        </Responsive>
      </div>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
  includeHomepageHeading: PropTypes.bool,
};

export default compose(withRouter)(DesktopContainer);
