import {
  SET_ACTIVE_PAGE,
  SET_FEATURED_VIDEOS,
  SET_VIDEOS,
  SET_ANALYTICS_VALUE,
  SET_EXTERNAL_VALUE,
  SET_LOADING_VIDEOS,
  SET_SHOW_BANNER,
  SET_BANNERS,
  SET_IDEAS,
  SET_LOADING_CONTENT,
  SET_LOADER,
  SET_TOOLS,
  SET_NORMING_GAME,
} from "../actions/index";

import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const initialState = {
  activeItem: "home",
  featuredVideos: [],
  videos: [],
  banners: [],
  ideas: [],
  tools: [],
  situationCards: [
    {
      text:
        "Brainstorm 20 possible speakers for a high school commencement address",
      used: false,
    },
    {
      text:
        "Rank the months of the year in order of most exciting to least exciting",
      used: false,
    },
    {
      text:
        "Identify 25 unique things that everyone in your group has in common",
      used: false,
    },
  ],
  characteristicCards: [
    {
      text: "I change my mind a lot",
      used: false,
    },
    {
      text: "I strongly dislike the person to my right, and it’s no secret",
      used: false,
    },
    {
      text:
        "Once I have my mind set on an idea, you won’t be able to change it",
      used: false,
    },
    {
      text:
        "I don’t do groups – and would rather do the assignment as a solo project",
      used: false,
    },
  ],
  externalCookie: false,
  analyticsCookie: false,
  loadingVideos: false,
  loadingContent: false,
  showBanner: true,
  displayLoader: false,
  loaderText: "",
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PAGE:
      return Object.assign({}, state, {
        ...state,
        activeItem: action.payload,
      });
    case SET_FEATURED_VIDEOS:
      return {
        ...state,
        featuredVideos: action.payload,
      };
    case SET_VIDEOS:
      return { ...state, videos: action.payload };
    case SET_EXTERNAL_VALUE:
      return {
        ...state,
        externalCookie: action.payload,
      };
    case SET_ANALYTICS_VALUE:
      return { ...state, analyticsCookie: action.payload };
    case SET_LOADING_VIDEOS:
      return { ...state, loadingVideos: action.payload };
    case SET_LOADING_CONTENT:
      return { ...state, loadingContent: action.payload };
    case SET_LOADER:
      return {
        ...state,
        displayLoader: action.payload.displayLoader,
        loaderText: action.payload.text,
      };
    case SET_SHOW_BANNER:
      return { ...state, showBanner: action.payload };
    case SET_BANNERS:
      return { ...state, banners: action.payload };
    case SET_IDEAS:
      return { ...state, ideas: action.payload };
    case SET_TOOLS:
      const orderedList = action.payload.slice().sort((a, b) => {
        if (
          dayjs(a.effective, "MM/DD/YYYY", true).isBefore(
            dayjs(b.effective, "MM/DD/YYYY", true)
          )
        )
          return 1;
        if (
          dayjs(b.effective, "MM/DD/YYYY", true).isBefore(
            dayjs(a.effective, "MM/DD/YYYY", true)
          )
        )
          return -1;
        return 0;
      });
      return { ...state, tools: orderedList };
    case SET_NORMING_GAME:
      return { ...state, game: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
