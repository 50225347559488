import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Grid, Header, Icon, Loader } from "semantic-ui-react";
import history from "../components/history";
import YouTubeVideoEmbed from "../components/YouTubeVideoEmbed";

const buildIdea = video => {
  if (video.length !== 1) {
    return (
      <Header as="h2" textAlign="center">
        Idea Not Found
      </Header>
    );
  } else {
    const post = video[0];
    return (
      <Grid container stackable className="idea">
        <Grid.Row centered>
          <Header as="h2" textAlign="center">
            {post.title}
          </Header>
        </Grid.Row>
        <Grid.Row className="idea-image">
          <YouTubeVideoEmbed videoId={post.id} title={post.title} />
        </Grid.Row>
        <Grid.Row className="idea-content">
          <div
            dangerouslySetInnerHTML={{ __html: `<p>${post.description}</p>` }}
          ></div>{" "}
        </Grid.Row>
        <Grid.Row>
          <a href="/ideas" onClick={handleClick}>
            <Icon name="arrow left"></Icon> Back
          </a>
        </Grid.Row>
      </Grid>
    );
  }
};

const buildMetaData = video => {
  if (video.length !== 1) {
    return (
      <Helmet>
        <title>Idea Not Found - My Job Is Fun</title>
      </Helmet>
    );
  } else {
    const post = video[0];
    return (
      <Helmet>
        <title>{post.title} - My Job Is Fun</title>
        <meta name="description" content={post.description} />
        <meta name="og:url" content={window.location.href} />
        <meta name="og:title" content={post.title} />
        <meta name="og:description" content={post.description} />
        <meta name="og:type" content="website" />
        {/* Twitter Card tags */}
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.description} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
    );
  }
};

const getVideoFromList = (videoList, videoId) => {
  return videoList.filter(video => {
    return video.id === videoId;
  });
};

const handleClick = e => {
  e.preventDefault();
  history.push("/ideas");
};

const Video = props => {
  let videoFromList = getVideoFromList(
    props.videos,
    props.location.search.substring(3)
  );

  return (
    <div>
      {buildMetaData(videoFromList)}
      <ResponsiveContainer includeHomepageHeading={false}>
        {props.loadingVideos ? (
          <Loader active inline="centered" size="huge" />
        ) : (
          buildIdea(videoFromList)
        )}
      </ResponsiveContainer>
    </div>
  );
};

const mapStateToProps = state => ({
  videos: state.videos,
  loadingVideos: state.loadingVideos
});

export default connect(mapStateToProps)(Video);
