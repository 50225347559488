import React from "react";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Button, Grid, Header } from "semantic-ui-react";
import Card from "../components/playing-card/Card";
import history from "../components/history";

class Game extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sitFlip: "",
      charFlip: "",
      hideStartButtons: false,
    };
  }
  isWidthLessThan979 = this.props.windowWidth < 979;

  componentDidMount() {
    this.timer = setTimeout(this.flipSitCard, 3000);
  }

  flipSitCard = () => {
    this.setState({ sitFlip: "flip" });
    clearTimeout(this.timer);
    this.timer2 = setTimeout(this.flipCharCard, 1000);
  };

  flipCharCard = () => {
    this.setState({ charFlip: "flip" });
    clearTimeout(this.timer2);
  };

  render() {
    return (
      <div>
        <ResponsiveContainer includeHomepageHeading={false}>
          <Grid container stackable className="courses">
            <Grid.Row centered>
              <Header as="h2" textAlign="center">
                Team Norming Activity
              </Header>
            </Grid.Row>
            <Grid.Row stretched textAlign="center">
              <Grid.Column>
                Here is where instructions for the game will do. Click the
                buttons below to start a new game or join an existing session!
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched className="video">
              {!this.props.isWidthLessThan979 && (
                <Grid.Column width={2}></Grid.Column>
              )}
              <Grid.Column
                verticalAlign="top"
                floated="right"
                width={this.props.isWidthLessThan979 ? 8 : 6}
              >
                <Card
                  type="Situation"
                  text="Generate a list of 20 tips to better e-mail ettiquite"
                  color="purple"
                  flipped={this.state.sitFlip}
                />
              </Grid.Column>
              <Grid.Column width={this.props.isWidthLessThan979 ? 8 : 6}>
                <Card
                  type="Characteristic"
                  text="I always have to have the last word in a conversation"
                  color="blue"
                  flipped={this.state.charFlip}
                />
              </Grid.Column>
              {!this.props.isWidthLessThan979 && (
                <Grid.Column width={2}></Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column width={4}> </Grid.Column>
              <Grid.Column width={4}>
                {!this.state.hideStartButtons && (
                  <Button
                    primary
                    onClick={() =>
                      history.push("/tools/team-norming-activity/start-game")
                    }
                  >
                    Start New Game
                  </Button>
                )}
              </Grid.Column>
              <Grid.Column width={4}>
                {!this.state.hideStartButtons && (
                  <Button
                    secondary
                    onClick={() =>
                      history.push("/tools/team-norming-activity/join-game")
                    }
                  >
                    Join Existing Game
                  </Button>
                )}
              </Grid.Column>
              <Grid.Column width={4}> </Grid.Column>
            </Grid.Row>
          </Grid>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default Game;
