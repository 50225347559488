import React from "react";
import { Helmet } from "react-helmet";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Grid, Header } from "semantic-ui-react";
import ProtoboxForm from "../components/ProtoboxForm";

const buildMetaData = (productName, productDescription) => {
	return (
		<Helmet>
			<title>{productName} - My Job Is Fun</title>
			<meta name="description" content={productDescription} />
			<meta name="og:url" content={window.location.href} />
			<meta name="og:title" content={productName} />
			<meta name="og:description" content={productDescription} />
			<meta name="og:type" content="website" />
			{/* Twitter Card tags */}
			<meta name="twitter:title" content={productName} />
			<meta name="twitter:description" content={productDescription} />
			<meta name="twitter:card" content="summary" />
		</Helmet>
	);
};

const Protobox = (props) => {
	const isWidthLessThan979 = props.windowWidth < 979;
	return (
		<div className="protobox">
			{buildMetaData("Protobox", "Purchase a complete protobox!")}
			<ResponsiveContainer includeHomepageHeading={false}>
				<Grid container stackable className="tool">
					{/* <Grid.Row centered>
            <Header as="h2" textAlign="center">
              Courses
            </Header>
          </Grid.Row> */}
					<Grid.Row stretched className="product">
						{!isWidthLessThan979 && <Grid.Column width={2}></Grid.Column>}
						{/* <Grid.Column verticalAlign="top" width={isWidthLessThan979 ? 8 : 7}>
							<Image src="../images/community_at_work.png" />
						</Grid.Column> */}
						{/* <Grid.Column width={isWidthLessThan979 ? 8 : 7}> */}
						<Grid.Column width={12}>
							<Header as="h1" textAlign="center">
								Protobox
							</Header>

							<p>
								Thanks for your interest in the gamification prototyping
								toolbox! We hand curate these items and ship them to you via
								USPS First Class mail. Please allow 2 weeks for processing and
								delivery. Each box contains:
							</p>
							<ul>
								<li>
									<strong>Post-Its:</strong> For quick ideas and iterations
								</li>
								<li>
									<strong>White board:</strong> Create game boards on the fly
								</li>
								<li>
									<strong>Clay:</strong> Mold them into player tokens or other
									physical objects
								</li>
								<li>
									<strong>Cubes:</strong> Add dimensionally to your games
								</li>
								<li>
									<strong>Dice:</strong> A game board staple
								</li>
								<li>
									<strong>Chits:</strong> Draw on designs to create tiles or
									markers
								</li>
								<li>
									<strong>Sand timers:</strong> Incorporate time pressure in
									your gameplay
								</li>
								<li>
									<strong>Spinners:</strong> Randomize player behavior
								</li>
								<li>
									<strong>Card decks:</strong> The ultimate gaming starting
									point
								</li>
							</ul>
							<p>
								Want to purchase your own items? Check out our{" "}
								<a href="https://courses.myjobisfun.com/protokit">
									Prototyping Tookit checklist
								</a>{" "}
								in our <a href="https://www.myjobisfun.com/tools">free tools</a>
								!
							</p>
							<p style={{ color: "purple" }}>$45 per box</p>
							<p>
								For more information and to submit your order, please submit
								your name, quantity, and address. We’ll follow up before
								shipping with an invoice and additional information!
							</p>
							<ProtoboxForm />
							{/* <Form>
								<Form.Group>
									<Form.Input label="Qty" type="number" width={4} />
									<Button fluid color="blue" onClick={() => handleClick("1")}>
										<Icon name="shopping cart" color="white"></Icon>Buy Now
									</Button>
								</Form.Group>
							</Form> */}
						</Grid.Column>
						{!isWidthLessThan979 && <Grid.Column width={2}></Grid.Column>}
					</Grid.Row>
				</Grid>
			</ResponsiveContainer>
		</div>
	);
};

export default Protobox;
