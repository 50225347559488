import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Header,
  Icon,
  Modal,
  Input,
  Grid
} from "semantic-ui-react";
import { addSubscriberFunction } from "../util/firebase-functions";
import ReactGA from "react-ga";
import FocusTrap from "focus-trap-react";

class HomepageHeading extends Component {
  state = {
    open: false,
    activeTrap: false,
    emailAddress: "",
    loading: false,
    subscribed: false,
    error: false,
    emailError: false
  };

  show = size => () => {
    if (this.props.analyticsCookie) {
      ReactGA.modalview("Join-the-fun");
    }
    this.setState({ size, open: true, activeTrap: true });
  };

  close = e => {
    e.preventDefault();
    this.setState({
      open: false,
      activeTrap: false,
      loading: false,
      subscribed: false,
      error: false,
      emailError: false
    });
  };

  handleCloseModalClick = () => {};

  unmountTrap = () => {
    if (this.state.activeTrap) {
      this.setState({ activeTrap: false });
    }
  };

  isEmailAddressValid = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  handleClick = event => {
    if (this.props.analyticsCookie) {
      ReactGA.event({
        category: "User",
        action: "Signed up for email"
      });
    }
    if (!this.isEmailAddressValid(this.state.emailAddress)) {
      this.setState({ emailError: true });
    } else {
      this.setState({ loading: true, emailError: false });

      addSubscriberFunction({ email: this.state.emailAddress })
        .then(response => {
          this.setState({ subscribed: true, loading: false });
        })
        .catch(error => {
          this.setState({ subscribed: false, loading: false, error: true });
        });
    }
  };

  handleChange = event => {
    this.setState({
      emailAddress: event.target.value
    });
  };

  render() {
    const { open, size, loading, subscribed, error, emailError } = this.state;
    return (
      <div>
        <Modal size={size} open={open} onClose={this.close} basic>
          <FocusTrap
            active={this.state.activeTrap}
            focusTrapOptions={{
              onDeactivate: this.unmountTrap,
              clickOutsideDeactivates: true,
              initialFocus: "#emailAddress"
            }}
          >
            <div>
              <Modal.Content>
                <Grid className="modalGrid">
                  <Grid.Column floated="right" width={2}>
                    <Grid.Row textAlign="right">
                      <a href="close-modal" onClick={this.close}>
                        <Icon name="close" size="large" alt="close modal" />
                      </a>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Row centered>
                    <Header
                      as="h2"
                      content="Turn spectacular ideas into a dazzling culture"
                      className="modalHeader"
                    />
                    <Header
                      as="h3"
                      content="Get free digital downloads, templates, and other
                  recommendations"
                      className="modalHeader"
                    />
                    {error && (
                      <Header
                        as="h3"
                        content="This is embarrassing! We seem to be having technical issues.  Email us at Jesse@myjobis.fun to get added."
                        className="modal-error-message"
                      />
                    )}
                    {emailError && (
                      <Header
                        as="h3"
                        content="Uh oh! There might be a typo.  Please check your email address."
                        className="modal-error-message"
                      />
                    )}
                    {subscribed && !error && (
                      <Header
                        as="h3"
                        content="Woohoo! You've been added to our list!"
                        className="modal-success-message"
                      />
                    )}
                    {!subscribed && !error && (
                      <div className="modalEmailInput">
                        <Input
                          id="emailAddress"
                          name="emailAddress"
                          disabled={loading}
                          placeholder="j.doe@gmail.com"
                          onChange={this.handleChange}
                          className={emailError ? "error" : ""}
                          aria-label="email address"
                          required
                          aria-required="true"
                        />
                        <Button
                          loading={loading}
                          primary
                          onClick={this.handleClick}
                        >
                          Sign up!
                        </Button>
                      </div>
                    )}
                    <div className="tempTeno">
                      <Header
                        as="h4"
                        content="You're signing up to receive emails from My Job is Fun"
                        className="modalHeader"
                      />
                    </div>
                  </Grid.Row>
                </Grid>
              </Modal.Content>
            </div>
          </FocusTrap>
        </Modal>

        <Container text>
          <Header
            as="h1"
            content="Work doesn't have to be a four-letter word!"
            className="main"
          />
          <Header
            as="h2"
            content="We provide tools to create remarkable cultures, boost engagement, and drive results."
            className="tagline"
          />
          <Button primary size="huge" onClick={this.show("small")}>
            Join the Fun!
            <Icon name="right arrow" />
          </Button>
        </Container>
      </div>
    );
  }
}

HomepageHeading.propTypes = {
  mobile: PropTypes.bool
};

const mapStateToProps = state => ({
  analyticsCookie: state.analyticsCookie
});

export default connect(mapStateToProps)(HomepageHeading);
