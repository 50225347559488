import React from "react";
import { Link } from "react-router-dom";
import { Grid, Header, Image } from "semantic-ui-react";

const NavHighlights = props => {
  return (
    <Grid container stackable className="nav-highlights">
      <Grid.Row columns={3} textAlign="center">
        <Grid.Column>
          <div className="navCard">
            <div>
              <Image
                src="../images/noun_book_video.png"
                size="small"
                centered
              />
            </div>
            <div className="nav-highlights-content">
              <Header as="h3">Courses</Header>
              <p>
                We've taught courses and workshops from respected Universities
                like Stanford and world-class companies like Lyft and Virgin
                America. We'd love to share some of our biggest AHAs with you
                and your team!
              </p>
            </div>
            <div className="nav-highlights-link">
              <Link to="/courses">Check out our courses</Link>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="navCard">
            <div>
              <Image src="../images/noun_exam.png" size="small" centered />
            </div>
            <div className="nav-highlights-content">
              <Header as="h3">Quick Start Guides</Header>
              <p>
                Ever feel like you’re trying to reinvent the wheel? We’re
                assembling some of our favorite checklists, assessments, and
                quickstart guides to help you leap into action to support a
                dazzling culture.
              </p>
            </div>
            <div className="nav-highlights-link">
              <Link to="/tools">Explore our tools</Link>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className="navCard">
            <div>
              <Image
                src="../images/noun_creative_idea.png"
                size="small"
                centered
              />
            </div>
            <div className="nav-highlights-content">
              <Header as="h3">Ideas</Header>
              <p>
                We love to share insights, triumphs (and a few failures) from
                our experience as practitioners working for some of the world’s
                most recognized brands.
              </p>
            </div>
            <div className="nav-highlights-link">
              <Link to="/ideas">Share our ideas</Link>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default NavHighlights;
