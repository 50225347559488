import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Grid, Header, Button, Image, Icon, Form } from "semantic-ui-react";
import ecommerceData from "../data/ecommerceData.json";

const handleClick = (productCode) => {
  window.alert("Checkout popup");
};

const getTool = (toolName) => {
  return ecommerceData.products.filter((product) => {
    return product.name === toolName;
  })[0];
};

const buildMetaData = (productName, productDescription) => {
  return (
    <Helmet>
      <title>{productName} - My Job Is Fun</title>
      <meta name="description" content={productDescription} />
      <meta name="og:url" content={window.location.href} />
      <meta name="og:title" content={productName} />
      <meta name="og:description" content={productDescription} />
      <meta name="og:type" content="website" />
      {/* Twitter Card tags */}
      <meta name="twitter:title" content={productName} />
      <meta name="twitter:description" content={productDescription} />
      <meta name="twitter:card" content="summary" />
    </Helmet>
  );
};

const Tool = (props) => {
  const { tool } = useParams();
  const isWidthLessThan979 = props.windowWidth < 979;
  const product = getTool(tool);
  return (
    <div>
      {buildMetaData(product.name, product.description)}
      <ResponsiveContainer includeHomepageHeading={false}>
        <Grid container stackable className="tool">
          {/* <Grid.Row centered>
            <Header as="h2" textAlign="center">
              Courses
            </Header>
          </Grid.Row> */}
          <Grid.Row stretched className="video">
            {!isWidthLessThan979 && <Grid.Column width={1}></Grid.Column>}
            <Grid.Column verticalAlign="top" width={isWidthLessThan979 ? 8 : 7}>
              <Image src="../images/community_at_work.png" />
            </Grid.Column>
            <Grid.Column width={isWidthLessThan979 ? 8 : 7}>
              <Header as="h3">{product.name}</Header>
              <Header as="h3">${product.cost}</Header>
              <Header as="h4">Description</Header>
              <p>{product.description}</p>
              <Form>
                <Form.Group>
                  <Form.Input label="Qty" type="number" width={4} />
                  <Button fluid color="blue" onClick={() => handleClick("1")}>
                    <Icon name="shopping cart" color="white"></Icon>Buy Now
                  </Button>
                </Form.Group>
              </Form>
            </Grid.Column>
            {!isWidthLessThan979 && <Grid.Column width={1}></Grid.Column>}
          </Grid.Row>
        </Grid>
      </ResponsiveContainer>
    </div>
  );
};

export default Tool;
