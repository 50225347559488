import React, { Component } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import HomepageHeading from "./HomepageHeading";
import Footer from "./Footer";
import Banner from "./Banner";

import {
  Button,
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Image,
} from "semantic-ui-react";

const getWidth = () => {
  const isSSR = typeof window === "undefined";

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = (e) => {
    e.preventDefault();
    this.setState({ sidebarOpened: true });
  };

  navigateToLogin = () => {
    window.location.assign("https://courses.myjobisfun.com/login");
  };

  render() {
    const { children, includeHomepageHeading } = this.props;
    const { sidebarOpened } = this.state;
    return (
      <div>
        <Responsive
          as={Sidebar.Pushable}
          getWidth={getWidth}
          maxWidth={Responsive.onlyMobile.maxWidth}
        >
          <Banner desktop={false} />
          <Sidebar
            as={Menu}
            animation="push"
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
            className="mobile-menu"
          >
            <Image
              src="/images/MJIF_logo.png"
              size="tiny"
              alt="My Job is Fun logo"
            />
            <Menu.Item as={NavLink} exact to="/" content="Home" />
            <Menu.Item as={NavLink} to="/courses" content="Courses" />
            <Menu.Item as={NavLink} to="/tools" content="Tools" />
            <Menu.Item as={NavLink} to="/ideas" content="Ideas" />
            <Menu.Item as={NavLink} to="/about" content="About Us" />
            <Menu.Item as={NavLink} to="/contact" content="Contact Us" />
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              textAlign="center"
              style={
                includeHomepageHeading
                  ? {
                      minHeight: 350,
                      padding: "1em 0em",
                      backgroundImage: "url(/images/desktop_main_mobile.jpg)",
                      backgroundPosition: "left",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }
                  : { padding: "1em 0em" }
              }
              vertical
            >
              <Container>
                <Menu pointing secondary size="large">
                  <div className="item">
                    <a href="menu" onClick={this.handleToggle}>
                      <Icon name="sidebar" alt="menu" />
                    </a>
                  </div>
                  <div className="right item">
                    <Button basic color="violet" onClick={this.navigateToLogin}>
                      Log in
                    </Button>
                  </div>
                </Menu>
              </Container>
              {includeHomepageHeading && <HomepageHeading mobile />}
            </Segment>

            {children}
          </Sidebar.Pusher>
          <Footer />
        </Responsive>
      </div>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
  includeHomepageHeading: PropTypes.bool,
};

export default compose(withRouter)(MobileContainer);
