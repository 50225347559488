import React from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import "./App.css";
import { Router, Switch, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import history from "./components/history";
import ReactGA from "react-ga";
import ScrollToTop from "./components/ScrollToTop";

import Home from "./pages/Home";
import Courses from "./pages/Courses";
import Ideas from "./pages/Ideas";
import Video from "./pages/Video";
import Blog from "./pages/Blog";
import Tools from "./pages/Tools";
import Tool from "./pages/Tool";
import About from "./pages/About";
import Workshops from "./pages/Workshops";
import Contact from "./pages/Contact";
import CookieSettings from "./pages/Cookies";
import Game from "./pages/Game";
import NormingGame from "./pages/NormingGame";
import JoinGame from "./pages/JoinGame";
import StartGame from "./pages/StartGame";
import {
	setVideos,
	setExternalValue,
	setAnalyticsValue,
	setLoadingVideos,
	setLoadingContent,
	setIdeas,
	setBanners,
	setTools,
} from "./actions/index";
import { db } from "./util/firebase-functions";
import { getVideos } from "./util/video-utility";
import Protobox from "./pages/Protobox";
require("typeface-roboto");

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			width: 0,
			height: 0,
			featuredVideos: [],
		};

		const cookies = new Cookies();
		let cookie = cookies.get("MJIFprivacy");
		if (cookie) {
			const external = "external=";
			const analytics = ",analytics=";
			const externalCookieValue = cookie.slice(
				external.length,
				cookie.indexOf(analytics)
			);
			const analyticsCookieValue = cookie.slice(
				cookie.indexOf(analytics) + analytics.length
			);
			this.props.setExternalValue(externalCookieValue === "true");
			this.props.setAnalyticsValue(analyticsCookieValue === "true");
		}
	}

	componentDidMount() {
		this.props.setLoadingVideos(true);
		getVideos("PLeYYcuS7MI5Lq5eibTMJ7vAArave1_xuM")
			.then((result) => {
				this.props.setLoadingVideos(false);
				this.props.setVideos(result);
			})
			.catch((error) => {
				this.props.setLoadingVideos(false);
			});
		this.props.setLoadingContent(true);
		db.collection("production")
			.get()
			.then((querySnapshot) => {
				let bannerArray = [];
				let ideaArray = [];
				let toolArray = [];
				querySnapshot.forEach((doc) => {
					switch (doc.data().type) {
						case "banner":
							bannerArray.push(doc.data());
							break;
						case "idea":
							ideaArray.push(doc.data());
							break;
						case "tool":
							toolArray.push(doc.data());
							break;
					}
				});
				this.props.setLoadingContent(false);
				this.props.setBanners(bannerArray);
				this.props.setIdeas(ideaArray);
				this.props.setTools(toolArray);
			})
			.catch((error) => {
				this.props.setLoadingContent(false);
			});

		this.updateWindowDimensions();
		window.addEventListener("resize", this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};

	setupAnalytics = () => {
		// Initialize google analytics page view tracking
		ReactGA.initialize("UA-144495692-2");
		history.listen((location) => {
			ReactGA.set({ page: location.pathname }); // Update the user's current page
			ReactGA.pageview(location.pathname); // Record a pageview for the given page
		});
	};

	render() {
		if (this.props.analyticsCookie) {
			this.setupAnalytics();
		}
		return (
			<Router history={history}>
				<ScrollToTop />
				<Switch>
					<Route
						exact
						path="/"
						render={(props) => <Home windowWidth={this.state.width} />}
					/>
					<Route
						exact
						path="/Home"
						render={(props) => <Home windowWidth={this.state.width} />}
					/>
					<Route
						exact
						path={`/courses`}
						render={(props) => <Courses windowWidth={this.state.width} />}
					/>
					<Route
						exact
						path={`/privacy`}
						render={(props) => (
							<CookieSettings windowWidth={this.state.width} />
						)}
					/>
					<Route
						exact
						path={`/ideas`}
						render={(props) => <Ideas windowWidth={this.state.width} />}
					/>
					<Route
						exact
						path={`/ideas/video`}
						render={(props) => (
							<Video windowWidth={this.state.width} location={props.location} />
						)}
					/>
					<Route
						exact
						path={`/ideas/blog`}
						render={(props) => (
							<Blog windowWidth={this.state.width} location={props.location} />
						)}
					/>
					<Route
						exact
						path={`/tools`}
						render={(props) => <Tools windowWidth={this.state.width} />}
					/>
					<Route
						exact
						path={`/tools/team-norming-activity`}
						render={(props) => <Game windowWidth={this.state.width} />}
					/>
					<Route
						exact
						path={`/tools/team-norming-activity/start-game`}
						render={(props) => <StartGame windowWidth={this.state.width} />}
					/>
					<Route
						exact
						path={`/tools/team-norming-activity/game`}
						render={(props) => <NormingGame windowWidth={this.state.width} />}
					/>
					<Route
						exact
						path={`/tools/team-norming-activity/join-game`}
						render={(props) => (
							<JoinGame
								windowWidth={this.state.width}
								location={props.location}
							/>
						)}
					/>
					<Route
						exact
						path={`/tools/:tool`}
						render={(props) => <Tool windowWidth={this.state.width} />}
					/>
					<Route
						exact
						path={`/games`}
						render={(props) => <Game windowWidth={this.state.width} />}
					/>

					<Route exact path={`/about`} render={(props) => <About />} />
					<Route exact path={`/workshops`} render={(props) => <Workshops />} />
					<Route exact path={`/contact`} render={(props) => <Contact />} />
					<Route exact path={`/protobox`} render={(props) => <Protobox />} />
					<Route
						exact
						path="/protokit"
						render={() =>
							(window.location = "https://courses.myjobisfun.com/protokit")
						}
					/>
					<Route render={(props) => <Home windowWidth={this.state.width} />} />
				</Switch>
			</Router>
		);
	}
}

const mapStateToProps = (state) => ({
	...state,
});

const mapDispatchToProps = (dispatch) => ({
	setVideos: (videos) => dispatch(setVideos(videos)),
	setExternalValue: (value) => dispatch(setExternalValue(value)),
	setAnalyticsValue: (value) => dispatch(setAnalyticsValue(value)),
	setLoadingVideos: (value) => dispatch(setLoadingVideos(value)),
	setLoadingContent: (value) => dispatch(setLoadingContent(value)),
	setBanners: (value) => dispatch(setBanners(value)),
	setIdeas: (value) => dispatch(setIdeas(value)),
	setTools: (value) => dispatch(setTools(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
