import React from "react";
import ResponsiveContainer from "../components/ResponsiveContainer";

class Workshops extends React.Component {
  state = {};
  render() {
    return (
      <ResponsiveContainer includeHomepageHeading={false}>
        <h1>Workshops</h1>
      </ResponsiveContainer>
    );
  }
}

export default Workshops;
