import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Icon, Loader, Dimmer } from "semantic-ui-react";
import DesktopContainer from "../components/DesktopContainer";
import MobileContainer from "../components/MobileContainer";
import CookieConsent from "react-cookie-consent";
import history from "./history";
import { setExternalValue, setAnalyticsValue } from "../actions/index";

const ResponsiveContainer = ({
  children,
  includeHomepageHeading,
  setAnalyticsValue,
  setExternalValue,
  displayLoader,
  loaderText,
}) => {
  return (
    <div className="responsive-container">
      {displayLoader && (
        <Dimmer active={true} inverted>
          <div className="full-loader">
            <Loader active color="blue" inline="centered" size="huge">
              {loaderText}
            </Loader>
          </div>
        </Dimmer>
      )}
      <CookieConsent
        style={{ background: "#0d6ca7" }}
        buttonStyle={{
          background: "#ffffff",
          borderRadius: "3px",
          boxShadow: "none",
          color: "black",
          cursor: "pointer",
          flex: "0 0 auto",
          padding: "5px 20px",
          margin: "15px",
        }}
        declineButtonStyle={{
          background: "#0191b8",
          borderRadius: "3px",
          boxShadow: "none",
          color: "white",
          cursor: "pointer",
          flex: "0 0 auto",
          padding: "5px 20px",
          margin: "15px",
        }}
        cookieName="MJIFprivacy"
        cookieValue="external=true,analytics=true"
        declineCookieValue="external=false,analytics=false"
        buttonText="Accept"
        enableDeclineButton={true}
        setDeclineCookie={true}
        declineButtonText="Learn more and customize"
        expires={91}
        buttonClasses="ui large primary button"
        onAccept={({ acceptedByScrolling }) => {
          if (acceptedByScrolling) {
            // triggered if user scrolls past threshold
          } else {
            setAnalyticsValue(true);
            setExternalValue(true);
          }
        }}
        onDecline={() => {
          history.push("/privacy");
        }}
      >
        Our site and our third party tools use cookies to enhance your
        experience as outlined in our{" "}
        <a
          href="https://www.iubenda.com/privacy-policy/50404109/cookie-policy"
          className="consent-link"
          title="Cookie Policy "
          rel="noopener noreferrer"
          target="_blank"
        >
          cookie policy{" "}
          <Icon name="external alternate" alt="opens in new window" />
        </a>
        . By clicking the Accept button in this notice you agree to our use of
        these.
      </CookieConsent>
      <DesktopContainer includeHomepageHeading={includeHomepageHeading}>
        {children}
      </DesktopContainer>
      <MobileContainer includeHomepageHeading={includeHomepageHeading}>
        {children}
      </MobileContainer>
    </div>
  );
};

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
  includeHomepageHeading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  analyticsCookie: state.analyticsCookie,
  externalCookie: state.externalCookie,
  displayLoader: state.displayLoader,
  loaderText: state.loaderText,
});

const mapDispatchToProps = (dispatch) => ({
  setExternalValue: (value) => dispatch(setExternalValue(value)),
  setAnalyticsValue: (value) => dispatch(setAnalyticsValue(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResponsiveContainer);
