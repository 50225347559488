import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Grid, Header, Image, Button } from "semantic-ui-react";
import ContactForm from "../components/ContactForm";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { sendMessageFunction } from "../util/firebase-functions";
import ReactGA from "react-ga";

class Contact extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    message: "",
    loading: false,
    sent: false,
    error: false,
    emailError: false,
    firstNameError: false,
    lastNameError: false
  };

  isEmailAddressValid = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: [event.target.value]
    });
  };

  handleClick = event => {
    if (this.props.analyticsCookie) {
      ReactGA.event({
        category: "User",
        action: "Sent contact us message"
      });
    }
    let emailErrorInd = this.state.emailAddress === "";
    const firstNameErrorInd = this.state.firstName === "";
    const lastNameErrorInd = this.state.lastName === "";

    if (!emailErrorInd) {
      if (!this.isEmailAddressValid(this.state.emailAddress)) {
        emailErrorInd = true;
      }
    }
    if (emailErrorInd || firstNameErrorInd || lastNameErrorInd) {
      this.setState({
        loading: false,
        apiError: true,
        sent: false,
        emailError: emailErrorInd,
        firstNameError: firstNameErrorInd,
        lastNameError: lastNameErrorInd
      });
    } else {
      this.setState({
        loading: true,
        apiError: false,
        sent: false,
        emailError: emailErrorInd,
        firstNameError: firstNameErrorInd,
        lastNameError: lastNameErrorInd
      });

      sendMessageFunction({
        email: this.state.emailAddress,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        message: this.state.message
      })
        .then(response => {
          this.setState({
            sent: true,
            loading: false,
            firstName: "",
            lastName: "",
            emailAddress: "",
            message: ""
          });
        })
        .catch(error => {
          this.setState({ sent: false, loading: false, apiError: true });
        });
    }
  };

  render() {
    const {
      loading,
      sent,
      apiError,
      emailAddress,
      firstName,
      lastName,
      message,
      emailError,
      firstNameError,
      lastNameError
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>Contact Us - My Job Is Fun</title>
          <meta
            name="description"
            content="For our team, brainstorming is kind of play, and we love fun. If you’ve got a challenge we can help reframe, a tough subject you’re having a hard time with, or a program you’d like help in designing, let’s schedule a quick chat to see if we can help connect you with solutions."
          />
          <meta name="og:url" content={window.location.href} />
          <meta name="og:title" content="Contact Us" />
          <meta
            name="og:description"
            content="For our team, brainstorming is kind of play, and we love fun. If you’ve got a challenge we can help reframe, a tough subject you’re having a hard time with, or a program you’d like help in designing, let’s schedule a quick chat to see if we can help connect you with solutions."
          />
          <meta name="og:type" content="website" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Contact Us" />
          <meta
            name="twitter:description"
            content="For our team, brainstorming is kind of play, and we love fun. If you’ve got a challenge we can help reframe, a tough subject you’re having a hard time with, or a program you’d like help in designing, let’s schedule a quick chat to see if we can help connect you with solutions."
          />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <ResponsiveContainer includeHomepageHeading={false}>
          <Grid container stackable className="contact">
            <Grid.Row centered>
              <Header as="h2" textAlign="center">
                Contact Us
              </Header>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header as="h2">Let's chat</Header>
                <p>
                  For our team, brainstorming is kind of play, and we love fun.
                  If you’ve got a challenge we can help reframe, a tough subject
                  you’re having a hard time with, or a program you’d like help
                  in designing, let’s schedule a quick chat to see if we can
                  help connect you with solutions.
                </p>
                <ContactForm
                  firstName={firstName}
                  lastName={lastName}
                  emailAddress={emailAddress}
                  message={message}
                  handleChange={this.handleChange}
                  handleClick={this.handleClick}
                  emailError={emailError}
                  firstNameError={firstNameError}
                  lastNameError={lastNameError}
                  apiError={apiError}
                  loading={loading}
                  sent={sent}
                />
              </Grid.Column>
              <Grid.Column>
                <Image src="../images/palm-trees.jpg" fluid alt="palm trees" />
                <Header as="h2">Where we play</Header>
                <p>
                  We’re proud of our home bases in Los Angeles and San Francisco
                  Bay, but we love to travel. We also have a network of partners
                  and friends (our Player 2 posse) throughout the U.S. and
                  abroad.
                </p>
                <Grid.Row className="social-section" centered>
                  <Button
                    circular
                    color="facebook"
                    icon="facebook"
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/myjobisfun",
                        "_blank"
                      )
                    }
                    alt="opens my job is fun facebook in new window"
                  />
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  analyticsCookie: state.analyticsCookie
});

export default connect(mapStateToProps)(Contact);
