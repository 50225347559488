import React from "react";
import { List } from "semantic-ui-react";

const createPlayersList = (playerList) => {
  return playerList.map((player, index) => {
    return (
      <List.Item key={index}>
        <List.Content>
          <List.Header>{player}</List.Header>
        </List.Content>
      </List.Item>
    );
  });
};

const PlayersList = (props) => {
  return (
    <List horizontal relaxed>
      {createPlayersList(props.playerList)}
    </List>
  );
};

export default PlayersList;
