import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid, Button, Icon } from "semantic-ui-react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { setShowBanner } from "../actions/index";

const buildBanner = (setShowBanner, showBanner, desktop, banners) => {
  const getBanner = () => {
    let banner;
    dayjs.extend(isBetween);
    for (let i = 0; i < banners.length; i++) {
      if (dayjs().isBetween(banners[i].effective, banners[i].expiration)) {
        banner = banners[i];
        break;
      }
    }
    return banner;
  };

  const banner = getBanner();
  if (!banner || !showBanner) {
    return <Grid.Row className="banner-empty"></Grid.Row>;
  }

  if (desktop) {
    return (
      <Grid.Row className="banner">
        <Grid.Column width={14}>
          <div dangerouslySetInnerHTML={{ __html: banner.bannerContent }}></div>
        </Grid.Column>
        <Grid.Column floated="right" width={2}>
          {banner.dismissButton && (
            <Button inverted color="blue" onClick={() => setShowBanner(false)}>
              Dismiss
            </Button>
          )}
        </Grid.Column>
      </Grid.Row>
    );
  } else {
    return (
      <Grid.Row className="banner">
        <Grid.Column width={14}>
          <div dangerouslySetInnerHTML={{ __html: banner.bannerContent }}></div>
        </Grid.Column>
        <Grid.Column floated="right" width={2}>
          {banner.dismissButton && (
            <a
              href="dismiss"
              onClick={(e) => {
                e.preventDefault();
                setShowBanner(false);
              }}
            >
              <Icon name="close" />
            </a>
          )}
        </Grid.Column>
      </Grid.Row>
    );
  }
};

const Banner = (props) => {
  return (
    <Grid verticalAlign="middle" textAlign="center">
      {buildBanner(
        props.setShowBanner,
        props.showBanner,
        props.desktop,
        props.banners
      )}
    </Grid>
  );
};

Banner.propTypes = {
  desktop: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  banners: state.banners,
  showBanner: state.showBanner,
});

const mapDispatchToProps = (dispatch) => ({
  setShowBanner: (value) => dispatch(setShowBanner(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);
