import React from "react";
import { Grid, Header, Segment, Card, Icon } from "semantic-ui-react";

const extra = (link) => {
	return (
		<a href={link} rel="noopener noreferrer" target="_blank">
			Purchase it <Icon name="external alternate" alt="opens in new window" />
		</a>
	);
};

const Bookshelf = (props) => {
	const isWidthLessThan1400 = props.windowWidth < 1400;
	return (
		<Segment vertical>
			<Grid stackable centered className="bookshelf">
				<Grid.Row textAlign="center">
					<Header as="h2">On our bookshelf</Header>
				</Grid.Row>
				<Grid.Row columns={isWidthLessThan1400 ? 3 : 5} stretched>
					<Grid.Column>
						<a
							href="https://www.amazon.com/gp/product/0316399264/"
							rel="noopener noreferrer"
							target="_blank"
						>
							<Card
								//fluid
								image="/images/joyful.jpg"
								header="Joyful: The Surprising Power of Ordinary Things to Create
            Extraordinary Happiness"
								description="by Ingrid Fetell Lee"
							/>
						</a>
					</Grid.Column>
					<Grid.Column>
						<a
							href="https://bookshop.org/books/design-for-how-people-learn/9780134211282"
							rel="noopener noreferrer"
							target="_blank"
						>
							<Card
								image="/images/design_for_how_people_learn.jpg"
								header="Design for How People Learn"
								description="by Julie Dirksen"
							/>
						</a>
					</Grid.Column>
					<Grid.Column>
						<a
							href="https://www.amazon.com/dp/1941577008/ref=cm_sw_em_r_mt_dp_U_QQppEb87EJ4W1"
							rel="noopener noreferrer"
							target="_blank"
						>
							<Card
								//fluid
								image="/images/performance_focused_smile_sheets.jpg"
								header="Performance-Focused Smile Sheets: A Radical Rethinking of a
              Dangerous Art Form"
								description="by Will Thalheimer"
								// extra={extra(
								//   "https://www.amazon.com/dp/1941577008/ref=cm_sw_em_r_mt_dp_U_QQppEb87EJ4W1"
								// )}
							/>
						</a>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default Bookshelf;
