import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Grid, Header, Button } from "semantic-ui-react";
import YouTubeVideoEmbed from "../components/YouTubeVideoEmbed";

class Courses extends React.Component {
  state = {};

  handleClick = (course) => {
    window.location.assign(`https://courses.myjobisfun.com/${course}`);
  };

  render() {
    const isWidthLessThan979 = this.props.windowWidth < 979;
    return (
      <div>
        <Helmet>
          <title>Courses - My Job Is Fun</title>
          <meta
            name="description"
            content="We've taught courses and workshops from respected Universities like Stanford and world-class companies like Lyft and Virgin America. We'd love to share some of our biggest AHAs with you and your team!"
          />
          <meta name="og:url" content={window.location.href} />
          <meta name="og:title" content="Courses" />
          <meta
            name="og:description"
            content="We've taught courses and workshops from respected Universities like Stanford and world-class companies like Lyft and Virgin America. We'd love to share some of our biggest AHAs with you and your team!"
          />
          <meta name="og:type" content="website" />
          {/* Twitter Card tags */}
          <meta name="twitter:title" content="Courses" />
          <meta
            name="twitter:description"
            content="We've taught courses and workshops from respected Universities like Stanford and world-class companies like Lyft and Virgin America. We'd love to share some of our biggest AHAs with you and your team!"
          />
          <meta name="twitter:card" content="summary" />
        </Helmet>
        <ResponsiveContainer includeHomepageHeading={false}>
          <Grid container stackable className="courses">
            <Grid.Row centered>
              <Header as="h2" textAlign="center">
                Courses
              </Header>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={1}></Grid.Column>
              <Grid.Column width={14}>
                <Header as="p" textAlign="center">
                  We build strong leaders, dazzling cultures, and experiences
                  worth writing home about. Try any one of our courses
                  completely risk-free. If it doesn't help you move the needle,
                  we'll cheerfully refund your money!
                </Header>
              </Grid.Column>
              <Grid.Column width={1}></Grid.Column>
            </Grid.Row>
            <Grid.Row className="course-type-heading">
              {!isWidthLessThan979 && <Grid.Column width={1}></Grid.Column>}
              <Grid.Column
                verticalAlign="top"
                width={isWidthLessThan979 ? 16 : 14}
              >
                <Header as="h3">Online Courses</Header>
              </Grid.Column>
              {!isWidthLessThan979 && <Grid.Column width={1}></Grid.Column>}
            </Grid.Row>
            <Grid.Row stretched className="video">
              {!isWidthLessThan979 && <Grid.Column width={1}></Grid.Column>}
              <Grid.Column
                verticalAlign="top"
                width={isWidthLessThan979 ? 8 : 7}
              >
                <YouTubeVideoEmbed
                  videoId="O683bg1zbJM"
                  title="Gamification For Learning & Performance Overview"
                />
              </Grid.Column>
              <Grid.Column width={isWidthLessThan979 ? 8 : 7}>
                <Header as="h3">
                  Power Play: Gamification for Learning & Performance
                </Header>
                <Header as="h4">Learn</Header>
                <p>
                  If you've missed one of our popular gamification classes at
                  Stanford, here's your chance to learn about gamification for
                  training and learning.
                </p>
                <Header as="h4">Highlights</Header>
                <ul>
                  <li>Learn 25+ game mechanics</li>
                  <li>1.5+ hours of video tutorials</li>
                  <li>5 real-world "boss battle challenges"</li>
                  <li>Downloadable tools, templates and references</li>
                  <li>
                    Examples from our decades of combined experience working at
                    companies and teaching at Stanford
                  </li>
                </ul>
                <Header as="h2">$24.99</Header>
                <Button
                  basic
                  color="violet"
                  onClick={() => this.handleClick("gamification")}
                >
                  View Course
                </Button>
              </Grid.Column>
              {!isWidthLessThan979 && <Grid.Column width={1}></Grid.Column>}
            </Grid.Row>
          </Grid>
        </ResponsiveContainer>
      </div>
    );
  }
}

Courses.propTypes = {
  windowWidth: PropTypes.number,
};

export default Courses;
