import React from "react";
import { Helmet } from "react-helmet";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Grid, Image, Header, Button } from "semantic-ui-react";

class About extends React.Component {
	handleClick = (userName) => {
		window.open(`https://www.linkedin.com/in/${userName}`, "_blank");
	};

	render() {
		return (
			<div>
				<Helmet>
					<title>About Us - My Job Is Fun</title>
					<meta
						name="description"
						content="We're full time practitioners, curators and passionate about people!  We've also lead teams at some of the world's most recognized brands and taught courses and workshops at Stanford University."
					/>
					<meta name="og:url" content={window.location.href} />
					<meta name="og:title" content="About Us - My Job Is Fun" />
					<meta
						name="og:description"
						content="We're full time practitioners, curators and passionate about people!  We've also lead teams at some of the world's most recognized brands and taught courses and workshops at Stanford University."
					/>
					<meta name="og:type" content="website" />
					{/* Twitter Card tags */}
					<meta name="twitter:title" content="About Us - My Job Is Fun" />
					<meta
						name="twitter:description"
						content="We're full time practitioners, curators and passionate about people!  We've also lead teams at some of the world's most recognized brands and taught courses and workshops at Stanford University."
					/>
					<meta name="twitter:card" content="summary" />
				</Helmet>
				<ResponsiveContainer includeHomepageHeading={false}>
					<Grid container stackable className="about">
						<Grid.Row centered>
							<Header as="h2" textAlign="center">
								Our Team
							</Header>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={4}>
								<Image src="/images/laura.jpg" alt="Laura Pickel" />
								<Header as="h3">Laura Pickel</Header>
								<Grid.Row className="social-section" centered>
									<Button
										circular
										color="linkedin"
										icon="linkedin"
										onClick={() => this.handleClick("laurapickel")}
										alt="opens Laura Pickel's LinkedIn profile in new page"
									/>
								</Grid.Row>
							</Grid.Column>
							<Grid.Column width={12}>
								<p>
									Laura has spent the past several years understanding the role
									design thinking and creative processes can and should play in
									workplaces. She does this both through her job at Lyft as a
									Learning Experience designer and the classes she teaches at
									the Stanford d.school, such as Building Creative Cultures in
									the Workplace and Organizational Psychology of Design
									Thinking.
								</p>
								<p>
									Because she enjoys filling her time with meaningful work, she
									also works for IDEOU as a teaching team lead on their Leading
									for Creativity and Designing for Change courses. She is a
									graduate of Williams College and the Learning, Design, and
									Technology program at the Stanford Graduate School of
									Education.
								</p>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={4}>
								<Image src="/images/jesse.jpg" alt="Jesse Harris" />
								<Header as="h3">Jesse Harris, SPHR</Header>
								<Grid.Row className="social-section" centered>
									<Button
										circular
										color="linkedin"
										icon="linkedin"
										onClick={() => this.handleClick("jesseharris15")}
										alt="opens Jesse Harris's LinkedIn profile in new page"
									/>
								</Grid.Row>
							</Grid.Column>
							<Grid.Column width={12}>
								<p>
									Jesse is a Director of People at Virgin Orbit, where he leads
									the Learning & Development, Recognition, and
									Engagementprograms for Sir Richard Branson's rocket company.
									He also teaches classes and workshops for Stanford Continuing
									Studies and the Stanford d.school. He specializes in program
									design and development and transforming boring events into
									memorable experiences. He also crossed off a big bucket list
									item by winning big on The Price is Right.
								</p>
								<p>
									Jesse earned a master’s in Learning, Design, and
									Technologyfrom the Stanford Graduate School of Education, a
									graduate certificate in entrepreneurship from Stanford’s
									Graduate School of Business, a bachelor’s in Anthropology from
									The University of Texas at San Antonio. He is a
									Gallup-certified Strengths Coach, and received accreditation
									as a Senior Professional in HR (SPHR) in 2009.
								</p>
								<p>
									Jesse brings his in-house experience leading Talent and HR
									teams at Virgin America, Alaska Airlines, Virgin Orbit, and
									Texas’ largest private employer, H-E-B. He has taught classes
									and workshops for Stanford, UTSA, and the Chief Learning
									Officer executive forum. He was named one of the 50 Top
									Leaders in Learning & Development – Americas by Corinium.
								</p>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</ResponsiveContainer>
			</div>
		);
	}
}

export default About;
