import React from "react";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Grid, Header, Radio, Icon } from "semantic-ui-react";
import { setExternalValue, setAnalyticsValue } from "../actions/index";

const setCookie = (external, analytics) => {
  const cookies = new Cookies();
  cookies.set("MJIFprivacy", `external=${external},analytics=${analytics}`, {
    path: "/"
  });
};

const removeCookie = cookieName => {
  const cookies = new Cookies();
  cookies.remove(cookieName);
};

const CookieSettings = props => {
  return (
    <div>
      <Helmet>
        <title>Cookie Settings - My Job Is Fun</title>
        <meta
          name="description"
          content="Ever feel like you’re trying to reinvent the wheel? We’ve assembled some of our favorite checklists, assessments, and quickstart guides to help you leap into action to support a dazzling culture."
        />
        <meta name="og:url" content={window.location.href} />
        <meta name="og:title" content="Tools" />
        <meta
          name="og:description"
          content="Ever feel like you’re trying to reinvent the wheel? We’ve assembled some of our favorite checklists, assessments, and quickstart guides to help you leap into action to support a dazzling culture."
        />
        <meta name="og:type" content="website" />
        {/* Twitter Card tags */}
        <meta name="twitter:title" content="Tools" />
        <meta
          name="twitter:description"
          content="Ever feel like you’re trying to reinvent the wheel? We’ve assembled some of our favorite checklists, assessments, and quickstart guides to help you leap into action to support a dazzling culture."
        />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <ResponsiveContainer includeHomepageHeading={false}>
        <Grid container stackable className="cookies">
          <Grid.Row centered>
            <Header as="h2" textAlign="center">
              Cookie Preferences
            </Header>
          </Grid.Row>
          <Grid.Row>
            We use the following cookie types to deliver the best content to you
            while striving to minimize the amount of data needed. You can manage
            individual cookie preferences by using the options below:
          </Grid.Row>
          <Grid.Row className="cookies-selections">
            <Grid.Column width={14}>
              <Header as="h3">
                Activities strictly necessary for the operation of
                www.myjobisfun.com and delivery of the Service
              </Header>
              <p>
                Www.myjobisfun.com uses Cookies or other Identifiers to carry
                out activities that are strictly necessary for the operation or
                delivery of the Service and, therefore, do not require Users to
                consent. Users can disable such Identifiers by modifying their
                browser or device settings as described in more detail within
                our{" "}
                <a
                  href="https://www.iubenda.com/privacy-policy/50404109/cookie-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  cookie policy{" "}
                  <Icon name="external alternate" alt="opens in new window" />
                </a>
                , but this may affect core operational functions or result in
                the unavailability of the Service.
              </p>
            </Grid.Column>
            <Grid.Column width={2}>
              <Radio toggle disabled checked={true} label="On" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="cookies-selections">
            <Grid.Column width={14}>
              <Header as="h3">
                Experience enhancement - displaying content from external
                platforms
              </Header>
              <p>
                Www.myjobisfun.com uses Cookies or other Identifiers to provide
                a personalized user experience by improving the quality of
                preference management options, and by enabling interaction with
                external networks and platforms.
              </p>
              <ul>
                <li>YouTube Video widget</li>
              </ul>
            </Grid.Column>
            <Grid.Column width={2}>
              <Radio
                toggle
                onChange={() => {
                  setCookie(!props.externalCookie, props.analyticsCookie);
                  props.setExternalValue(!props.externalCookie);
                }}
                checked={props.externalCookie}
                label={props.externalCookie ? "On" : "Off"}
                name="external"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="cookies-selections">
            <Grid.Column width={14}>
              <Header as="h3">Anlalytics</Header>
              <p>
                Www.myjobisfun.com uses Cookies or other Identifiers to measure
                traffic and analyze User behavior with the goal of improving the
                Service.
              </p>
              <ul>
                <li>Google Analytics</li>
              </ul>
            </Grid.Column>
            <Grid.Column width={2}>
              <Radio
                toggle
                onChange={() => {
                  setCookie(props.externalCookie, !props.analyticsCookie);
                  if (props.analyticsCookie) {
                    removeCookie("_ga");
                    removeCookie("_gid");
                    removeCookie("_gat");
                  }
                  props.setAnalyticsValue(!props.analyticsCookie);
                }}
                checked={props.analyticsCookie}
                label={props.analyticsCookie ? "On" : "Off"}
                name="analytics"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </ResponsiveContainer>
    </div>
  );
};

const mapStateToProps = state => ({
  externalCookie: state.externalCookie,
  analyticsCookie: state.analyticsCookie
});

const mapDispatchToProps = dispatch => ({
  setExternalValue: value => dispatch(setExternalValue(value)),
  setAnalyticsValue: value => dispatch(setAnalyticsValue(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CookieSettings);
