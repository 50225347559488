import React from "react";
import { Container, Grid, Segment, Image, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

const Footer = props => {
  return (
    <Segment
      inverted
      vertical
      style={{ padding: "5em 0em" }}
      className="footer"
    >
      <Container>
        <Grid inverted stackable verticalAlign="middle">
          <Grid.Row columns={3}>
            <Grid.Column>
              <p>myjobisfun.com is a Nellbridge company</p>
              <p>© 2020 Nellbridge Group, LLC</p>
            </Grid.Column>
            <Grid.Column>
              <Image src="/images/nellbridge_blk.png" centered size="small" />
            </Grid.Column>
            <Grid.Column float="right">
              <Grid.Row>
                <a
                  href="https://www.iubenda.com/privacy-policy/50404109"
                  className="iubenda-white no-brand iubenda-embed"
                  title="Privacy Policy "
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy{" "}
                  <Icon name="external alternate" alt="opens in new window" />
                </a>
                <a
                  href="https://www.iubenda.com/privacy-policy/50404109/cookie-policy"
                  className="iubenda-white no-brand iubenda-embed"
                  title="Cookie Policy "
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Cookie Policy{" "}
                  <Icon name="external alternate" alt="opens in new window" />
                </a>
              </Grid.Row>
              <Grid.Row>
                <Link to="/privacy">Cookie Preferences</Link>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default Footer;
