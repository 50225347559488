import React from "react";
import { Embed, Grid, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import history from "./history";

const buildEmbededVideo = (videoId, image) => {
  return (
    <Embed
      autoplay={false}
      brandedUI={false}
      color="white"
      hd={false}
      id={videoId}
      placeholder={image}
      source="youtube"
    />
  );
};

const buildYouTubeVideoLink = videoId => {
  return (
    <Grid textAlign="center">
      <Grid.Row className="video-disabled">
        <Grid.Column>
          <Icon name="hide" size="huge" color="grey" />
          <div className="video-disabled-description">
            <p>
              Embedded Video has been disabled due to your selected{" "}
              <a
                href="privacy"
                onClick={e => {
                  e.preventDefault();
                  history.push("/privacy");
                }}
              >
                cookie preferences
              </a>
              .
            </p>
          </div>
          <a
            href={`https://www.youtube.com/watch?v=${videoId}`}
            className="video-disabled-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Video on YouTube.com{" "}
            <Icon name="external alternate" alt="opens new window" />
          </a>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const YouTubeVideoEmbed = props => {
  return (
    <div
      className="video"
      style={{
        paddingBottom: "56.25%" /* 16:9 */
      }}
    >
      {props.externalCookie
        ? buildEmbededVideo(props.videoId, props.image)
        : buildYouTubeVideoLink(props.videoId)}
    </div>
  );
};

const mapStateToProps = state => ({
  externalCookie: state.externalCookie
});

export default connect(mapStateToProps)(YouTubeVideoEmbed);
