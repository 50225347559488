import React from "react";
import { connect } from "react-redux";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { Button, Grid, Form, Input, Header, Message } from "semantic-ui-react";
import { setLoader, setNormingGame } from "../actions/index";
import {
  joinNewGameFunction,
  createCharacteristicCardsFunction,
} from "../util/firebase-functions";
import history from "../components/history";

class JoinGame extends React.Component {
  constructor(props) {
    super(props);

    let gameCode = "";
    if (props.location.search.substring(1)) {
      gameCode = props.location.search.substring(1);
    }

    this.state = {
      gameCode: gameCode,
      joinGameError: false,
      codeError: false,
      nameError: false,
    };
  }

  isWidthLessThan979 = this.props.windowWidth < 979;

  handleCodeChange = (event) => {
    this.setState({ gameCode: event.target.value, codeError: false });
  };

  handleNameChange = (event) => {
    this.setState({ playerName: event.target.value, nameError: false });
  };

  handleJoinGame = () => {
    if (this.state.gameCode === "" || this.state.playerName === "") {
      if (this.state.playerName === "" && this.state.gameCode === "") {
        this.setState({ nameError: true, codeError: true });
      } else if (this.state.gameCode === "") {
        this.setState({ codeError: true });
      } else {
        this.setState({ nameError: true });
      }
    } else {
      this.setState({
        showJoinGameForm: false,
        joinGameError: false,
        loading: true,
      });
      this.props.setLoader({ displayLoader: true, text: "Joining Game!" });
      joinNewGameFunction({
        playerName: this.state.playerName,
        gameCode: this.state.gameCode,
      })
        .then((resp) => {
          if (resp.data.status === 201) {
            this.props.setLoader({ displayLoader: false, text: "" });
            this.props.setNormingGame({
              playerID: resp.data.playerID,
              gameID: resp.data.gameID,
            });
            createCharacteristicCardsFunction({
              playerID: resp.data.playerID,
              playerNumber: resp.data.playerNumber,
            });
            history.push("/tools/team-norming-activity/game");
          } else {
            this.setState({
              showJoinGameForm: true,
              joinGameError: true,
              loading: false,
            });
            this.props.setLoader({ displayLoader: false, text: "" });
          }
        })

        .catch((err) => {});
    }
  };

  render() {
    return (
      <div>
        <ResponsiveContainer includeHomepageHeading={false}>
          <Grid container stackable className="courses">
            <Grid.Row centered>
              <Header as="h2" textAlign="center">
                Team Norming Activity
              </Header>
            </Grid.Row>
            <Grid.Row centered textAlign="center">
              {!this.props.isWidthLessThan979 && (
                <Grid.Column width={5}></Grid.Column>
              )}
              <Grid.Column width={this.props.isWidthLessThan979 ? 16 : 6}>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field
                      id="name"
                      control={Input}
                      label="Your Name"
                      placeholder=""
                      required
                      error={this.state.nameError}
                      onChange={this.handleNameChange}
                    />

                    <Form.Field
                      id="gameCode"
                      control={Input}
                      label="Game Code"
                      placeholder=""
                      value={this.state.gameCode}
                      required
                      error={this.state.codeError}
                      onChange={this.handleCodeChange}
                    />
                  </Form.Group>
                </Form>

                <Button primary onClick={this.handleJoinGame}>
                  Let's Go!
                </Button>
                {this.state.joinGameError && (
                  <Message negative>
                    <Message.Header>
                      We're sorry we couldn't add you to the game.
                    </Message.Header>
                    <p>Please double check your game code.</p>
                  </Message>
                )}
              </Grid.Column>
              {!this.props.isWidthLessThan979 && (
                <Grid.Column width={5}></Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  displayLoader: state.displayLoader,
});
const mapDispatchToProps = (dispatch) => ({
  setLoader: (value) => dispatch(setLoader(value)),
  setNormingGame: (value) => dispatch(setNormingGame(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinGame);
