import React from "react";
import { connect } from "react-redux";
import ResponsiveContainer from "../components/ResponsiveContainer";
import { setLoader, setNormingGame } from "../actions/index";
import { Button, Grid, Form, Input, Header, Message } from "semantic-ui-react";
import {
  createNewGameFunction,
  createCharacteristicCardsFunction,
} from "../util/firebase-functions";
import history from "../components/history";

class StartGame extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //   gameID: "",
      //   gameShortID: "",
      //   gameStatus: "",
      //   playerID: "",
      //   playerName: "",
      //   listOfPlayers: [],
      //   gameCode: "",
      //   situationCards: [],
      //   characteristicCards: [],
      //   playerType: "",
      //   playCount: 0,
      //   sitFlip: "",
      //   charFlip: "",
      //   showNewGameForm: true,
      //   newGameError: false,
      //   loading: false,
      //   nameError: false,
      //   hideTimer: true,
    };
  }
  isWidthLessThan979 = this.props.windowWidth < 979;

  handleNameChange = (event) => {
    this.setState({ playerName: event.target.value, nameError: false });
  };

  handleResetNewGame = () => {
    this.setState({
      //   gameID: "",
      //   gameShortID: "",
      //   gameStatus: "",
      //   playerID: "",
      //   playerName: "",
      //   listOfPlayers: [],
      //   gameCode: "",
      //   situationCards: [],
      //   characteristicCards: [],
      //   playerType: "",
      //   playCount: 0,
      //   sitFlip: "",
      //   charFlip: "",
      //   showNewGameForm: true,
      //   newGameError: false,
      //   loading: false,
      //   nameError: false,
      //   hideTimer: true,
    });
  };

  handleNewGame = () => {
    if (this.state.playerName === "") {
      this.setState({ nameError: true });
    } else {
      this.props.setLoader({
        displayLoader: true,
        text: "Setting up the game!",
      });
      this.setState({
        newGameError: false,
      });
      createNewGameFunction({ playerName: this.state.playerName })
        .then((resp) => {
          if (resp.data.status === 201) {
            this.props.setLoader({ displayLoader: false, text: "" });

            this.props.setNormingGame({
              playerID: resp.data.playerID,
              gameID: resp.data.gameID,
            });

            createCharacteristicCardsFunction({
              playerID: resp.data.playerID,
              playerNumber: resp.data.playerNumber,
            });
            history.push("/tools/team-norming-activity/game");
          } else {
            this.props.setLoader({ displayLoader: false, text: "" });
            this.setState({
              newGameError: true,
            });
          }
        })
        .catch((err) => {});
    }
  };

  render() {
    return (
      <div>
        <ResponsiveContainer includeHomepageHeading={false}>
          <Grid container stackable className="courses">
            <Grid.Row centered>
              <Header as="h2" textAlign="center">
                Team Norming Activity
              </Header>
            </Grid.Row>
            <Grid.Row>
              {!this.props.isWidthLessThan979 && (
                <Grid.Column width={5}></Grid.Column>
              )}
              <Grid.Column width={this.props.isWidthLessThan979 ? 16 : 6}>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field
                      id="name"
                      control={Input}
                      label="Your Name"
                      placeholder=""
                      required
                      error={this.state.nameError}
                      onChange={this.handleNameChange}
                    />
                  </Form.Group>
                </Form>

                <Button primary onClick={this.handleNewGame}>
                  Let's Start!
                </Button>

                {this.state.newGameError && (
                  <Message negative>
                    <Message.Header>
                      We're sorry we're having trouble starting a new game right
                      now.
                    </Message.Header>
                    <p>Try once more.</p>
                  </Message>
                )}
              </Grid.Column>
              {!this.props.isWidthLessThan979 && (
                <Grid.Column width={5}></Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  displayLoader: state.displayLoader,
});
const mapDispatchToProps = (dispatch) => ({
  setLoader: (value) => dispatch(setLoader(value)),
  setNormingGame: (value) => dispatch(setNormingGame(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartGame);
