import React from "react";
import { Form, Input, TextArea, Button, Message } from "semantic-ui-react";

const ContactForm = ({
  firstName,
  lastName,
  emailAddress,
  message,
  handleChange,
  handleClick,
  loading,
  emailError,
  firstNameError,
  lastNameError,
  apiError,
  sent
}) => {
  const formError = emailError || firstNameError || lastNameError || apiError;
  return (
    <Form loading={loading} error={formError} success={sent}>
      <Form.Group widths="equal">
        <Form.Field
          id="firstName"
          control={Input}
          label="First name"
          placeholder="First name"
          value={firstName}
          required
          onChange={handleChange}
          className={firstNameError ? "error" : ""}
        />
        <Form.Field
          id="lastName"
          control={Input}
          label="Last name"
          placeholder="Last name"
          value={lastName}
          required
          onChange={handleChange}
          className={lastNameError ? "error" : ""}
        />
      </Form.Group>
      <Form.Field
        id="emailAddress"
        control={Input}
        label="Email address"
        placeholder="j.doe@gmail.com"
        value={emailAddress}
        required
        onChange={handleChange}
        className={emailError ? "error" : ""}
      />
      <Form.Field
        id="message"
        control={TextArea}
        label="Message"
        placeholder="Anything you want to say or ask. Seriously!"
        value={message}
        onChange={handleChange}
      />
      <Button fluid color="violet" onClick={handleClick}>
        Send!
      </Button>
      {emailError || firstNameError || lastNameError ? (
        <Message
          error
          header="Uh oh"
          content="Looks like there might be a typo.  Please correct the highlighted fields and re-submit."
        />
      ) : (
        <Message
          error
          header="Uh oh"
          content="Sorry for the delay but we seem to be having technical issues.  Please email us at Jesse@myjobis.fun"
        />
      )}
      <Message
        success
        header="Sent!"
        content="Woohoo, you're message was sent!"
      />
    </Form>
  );
};

export default ContactForm;
