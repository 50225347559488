import React from "react";

import { Grid, Header, Image } from "semantic-ui-react";

const WhoWeAre = props => {
  const isWidthLessThan768 = props.windowWidth < 768;
  return (
    <Grid container stackable verticalAlign="middle" className="who-we-are">
      <Grid.Row>
        <Grid.Column>
          <Header as="h2" textAlign="center">
            Who We Are
          </Header>
          <p>
            We aren't a technology company trying to create the newest gizmo
            you'll never lose. We aren't consultants interested only in fast
            talking and hollow results.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Image
            src="../images/noun_arrange.png"
            size={isWidthLessThan768 ? "small" : "medium"}
            centered
          />
        </Grid.Column>
        <Grid.Column floated="right" width={8}>
          <Header as="h3" textAlign="center">
            We're full time practitioners
          </Header>
          <p>
            who believe there's a better way to create an engaged workplace -
            one where being human-centered and results-driven aren't mutually
            exclusive.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          {isWidthLessThan768 && (
            <Image src="../images/noun_book_search.png" size="small" centered />
          )}
          <Header as="h3" textAlign="center">
            We're curators
          </Header>
          <p>
            gathering tips, resources, and other tools that will help you create
            a remarkable culture...not because it's our job, but because we
            believe people deserve better. And who better to give it to them
            than you?
          </p>
        </Grid.Column>
        {!isWidthLessThan768 && (
          <Grid.Column floated="right" width={8}>
            <Image
              src="../images/noun_book_search.png"
              size="medium"
              centered
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Image
            src="../images/noun_winner.png"
            size={isWidthLessThan768 ? "small" : "medium"}
            centered
          />
        </Grid.Column>
        <Grid.Column floated="right" width={8}>
          <Header as="h3" textAlign="center">
            We're passionate about people
          </Header>
          <p>
            and we've planted our flag to make human resources more human. We're
            thrilled you're joining us for the ride!
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default WhoWeAre;
