import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import history from "./history";

const buildYouTubeIframe = (videoId, title) => {
  return (
    <iframe
      title={`${title} video`}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
      }}
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
    />
  );
};

const buildYouTubeVideoLink = videoId => {
  return (
    <Grid textAlign="center">
      <Grid.Row className="video-disabled">
        <Grid.Column>
          <Icon name="hide" size="huge" color="grey" />
          <div className="video-disabled-description">
            <p>
              Embedded Video has been disabled due to your selected{" "}
              <a
                href="privacy"
                onClick={e => {
                  e.preventDefault();
                  history.push("/privacy");
                }}
              >
                cookie preferences
              </a>
              .
            </p>
          </div>
          <a
            href={`https://www.youtube.com/watch?v=${videoId}`}
            className="video-disabled-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Video on YouTube.com{" "}
            <Icon name="external alternate" alt="opens new window" />
          </a>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const YouTubeVideoEmbed = props => {
  return (
    <div
      className="video"
      style={{
        paddingBottom: "56.25%" /* 16:9 */
      }}
    >
      {props.externalCookie
        ? buildYouTubeIframe(props.videoId, props.title)
        : buildYouTubeVideoLink(props.videoId)}
    </div>
  );
};

const mapStateToProps = state => ({
  externalCookie: state.externalCookie
});

export default connect(mapStateToProps)(YouTubeVideoEmbed);
