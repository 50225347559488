import firebase from "firebase/app";
import "firebase/functions";
import "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyCEeMtWZTkioEC195goMa05BYOUQ25Lsc8",
	authDomain: "mjif-33873.firebaseapp.com",
	databaseURL: "https://mjif-33873.firebaseio.com",
	projectId: "mjif-33873",
};

firebase.initializeApp(firebaseConfig);

export const addSubscriberFunction = firebase
	.functions()
	.httpsCallable("addSubscriber");

export const sendMessageFunction = firebase
	.functions()
	.httpsCallable("sendMessage");

export const sendProtoboxMessageFunction = firebase
	.functions()
	.httpsCallable("sendProtoboxMessage");

export const getVideoList = firebase.functions().httpsCallable("getVideoList");

export const getVideo = firebase.functions().httpsCallable("getVideo");

export const getContent = firebase.firestore().collection("production").get();

export const db = firebase.firestore();

export const dealCardsFunction = firebase
	.functions()
	.httpsCallable("dealCards");

export const createCharacteristicCardsFunction = firebase
	.functions()
	.httpsCallable("createCharacteristicCards");

export const createNewGameFunction = firebase
	.functions()
	.httpsCallable("createNewGame");

export const endGameFunction = firebase.functions().httpsCallable("endGame");

export const joinNewGameFunction = firebase
	.functions()
	.httpsCallable("joinNewGame");
