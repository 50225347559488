import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import ResponsiveContainer from "../components/ResponsiveContainer";
import FeaturedCourse from "../components/FeaturedCourse";
import FeaturedIdeas from "../components/FeaturedIdeas";
import NavHighlights from "../components/NavHighlights";
import Bookshelf from "../components/Bookshelf";
import WhoWeAre from "../components/WhoWeAre";

const Home = (props) => (
	<div>
		<Helmet>
			<title>My Job Is Fun – We help make yours fun, too</title>
			<meta
				name="description"
				content="Offering coaching, consulting and design services for onboarding, orientation, leadership development, training, team building, and company culture."
			/>
			<meta name="og:url" content={window.location.href} />
			<meta
				name="og:title"
				content="My Job Is Fun – We help make yours fun, too"
			/>
			<meta
				name="og:description"
				content="Offering coaching, consulting and design services for onboarding, orientation, leadership development, training, team building, and company culture."
			/>
			<meta name="og:type" content="website" />
			{/* Twitter Card tags */}
			<meta
				name="twitter:title"
				content="My Job Is Fun – We help make yours fun, too"
			/>
			<meta
				name="twitter:description"
				content="Offering coaching, consulting and design services for onboarding, orientation, leadership development, training, team building, and company culture."
			/>
			<meta name="twitter:card" content="summary" />
		</Helmet>
		<ResponsiveContainer includeHomepageHeading={true}>
			<FeaturedCourse windowWidth={props.windowWidth} />
			<NavHighlights />
			<WhoWeAre windowWidth={props.windowWidth} />
			<FeaturedIdeas
				windowWidth={props.windowWidth}
				featuredVideos={props.featuredVideos}
			/>
			<Bookshelf windowWidth={props.windowWidth} />
		</ResponsiveContainer>
	</div>
);

Home.propTypes = {
	featuredVideos: PropTypes.array,
	windowWidth: PropTypes.number,
};

export default Home;
