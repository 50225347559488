import React from "react";
import { Image, Header, Label, Icon } from "semantic-ui-react";
import YouTubeVideoEmbed from "../components/YouTubeVideoEmbed_EN";
import history from "../components/history";

const handleClick = (e, id, type) => {
  e.preventDefault();
  history.push(`/ideas/${type}?q=${id}`);
};

const MyCard = ({ type, thumbnail, title, summary, videoId, postId }) => {
  let id;
  if (type === "blog") {
    id = postId;
  } else {
    id = videoId;
  }
  return (
    <article className="mjif-card">
      <div>
        {type === "blog" ? (
          <Image src={thumbnail} fluid />
        ) : (
          <YouTubeVideoEmbed
            videoId={videoId}
            title={title}
            image={thumbnail}
          />
        )}
      </div>
      {type === "blog" ? (
        <Label color="black">Blog</Label>
      ) : (
        <Label color="yellow">Video</Label>
      )}
      <a href={title} onClick={event => handleClick(event, id, type)}>
        <Header as="h3">{title}</Header>
      </a>
      <p>{summary}</p>
      <div className="mjif-card-view-post">
        <a
          href={`ideas/${type}?q=${id}`}
          onClick={event => handleClick(event, id, type)}
        >
          View Post <Icon name="arrow right"></Icon>
        </a>
      </div>
    </article>
  );
};

export default MyCard;
