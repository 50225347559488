import React from "react";
import { connect } from "react-redux";
import ResponsiveContainer from "../components/ResponsiveContainer";
import Timer from "../components/Timer";
import { setLoader, setNormingGame } from "../actions/index";
import { Button, Grid, Header, Message } from "semantic-ui-react";
import Card from "../components/playing-card/Card";
import PlayersList from "../components/PlayersList";
import history from "../components/history";
import {
  db,
  endGameFunction,
  dealCardsFunction,
} from "../util/firebase-functions";

class NewGame extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameShortID: "",
      gameStatus: "",
      playerName: "",
      listOfPlayers: [],
      gameCode: "",
      situationCards: [],
      characteristicCards: [],
      playerType: "",
      playCount: 0,
      sitFlip: "",
      charFlip: "",
      showNewGameForm: true,
      newGameError: false,
      loading: false,
      nameError: false,
      hideTimer: true,
    };
  }
  isWidthLessThan979 = this.props.windowWidth < 979;

  componentDidMount() {
    this.unsubscribePlayers = db
      .collection("games")
      .doc(this.props.game.gameID)
      .onSnapshot((doc) => {
        let players = doc.data().players;
        const playerNames = players.map((player) => {
          return player.name;
        });
        this.setState({
          listOfPlayers: playerNames,
          playCount: doc.data().playCount,
          situationCards: doc.data().situations,
          gameShortID: doc.data().shortID,
          gameStatus: doc.data().status,
        });
      });

    this.unsubscribeCards = db
      .collection("players")
      .doc(this.props.game.playerID)
      .onSnapshot((doc) => {
        if (doc.data()) {
          this.setState({
            characteristicCards: doc.data().characteristics,
            playerType: doc.data().role,
          });
          if (doc.data().playCount !== 0) {
            this.delayCardFlip();
          }
        } else {
          this.setState({ gameStatus: "DONE" });
        }
      });
  }

  componentWillUnmount() {
    this.unsubscribePlayers();
    this.unsubscribeCards();
  }

  handleNameChange = (event) => {
    this.setState({ playerName: event.target.value, nameError: false });
  };

  delayCardFlip = () => {
    this.props.setLoader({
      displayLoader: false,
      text: "",
    });
    this.timer = setTimeout(this.flipCards, 2000);
  };

  flipCards = () => {
    this.setState({ sitFlip: "flip" });
    clearTimeout(this.timer);
    this.timer2 = setTimeout(this.flipCharCard, 1000);
  };

  flipCharCard = () => {
    this.props.setLoader({ displayLoader: false, text: "" });
    this.setState({ charFlip: "flip", hideTimer: false });
    clearTimeout(this.timer2);
  };

  handleDealCards = () => {
    this.props.setLoader({
      displayLoader: true,
      text: "Shuffling the deck and dealing cards to all participants!",
    });
    this.setState({
      // gameStatus: "OPEN",
      sitFlip: "",
      charFlip: "",
      hideTimer: true,
    });
    dealCardsFunction({ gameID: this.props.game.gameID })
      .then((resp) => {
        if (resp.data.status !== 201) {
          this.props.setLoader({
            displayLoader: false,
            text: "",
          });
          //this.setState({ gameStatus: "NEW" });
        }
      })
      .catch((err) => {});
  };

  handleEndGame = () => {
    this.props.setLoader({
      displayLoader: true,
      text: "Putting all the cards back in the box!",
    });
    this.setState({
      loading: true,
    });
    endGameFunction({ gameID: this.props.game.gameID })
      .then((resp) => {
        if ((resp.data.status = 201)) {
          this.props.setLoader({
            displayLoader: false,
            text: "",
          });
          this.setState({
            gameStatus: "DONE",
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {});
  };

  render() {
    return (
      <div>
        <ResponsiveContainer includeHomepageHeading={false}>
          <Grid container stackable className="courses">
            <Grid.Row centered>
              <Header as="h2" textAlign="center">
                Team Norming Activity
              </Header>
              {this.state.playCount > 3 && (
                <Message icon>
                  <Message.Content>
                    <Message.Header>
                      Oh no! You've reached your last play. Make it count!
                    </Message.Header>
                  </Message.Content>
                </Message>
              )}
            </Grid.Row>

            <Grid.Row stretched className="video">
              {!this.props.isWidthLessThan979 && (
                <Grid.Column width={2}></Grid.Column>
              )}
              <Grid.Column
                verticalAlign="top"
                width={this.props.isWidthLessThan979 ? 8 : 6}
              >
                {this.state.playCount !== 0 &&
                  this.state.gameStatus === "OPEN" && (
                    <Card
                      type="Situation"
                      text={this.state.situationCards[this.state.playCount - 1]}
                      color="purple"
                      flipped={this.state.sitFlip}
                    />
                  )}
              </Grid.Column>
              <Grid.Column width={this.props.isWidthLessThan979 ? 8 : 6}>
                {this.state.playCount !== 0 &&
                  this.state.gameStatus === "OPEN" && (
                    <Card
                      type="Characteristic"
                      text={
                        this.state.characteristicCards[this.state.playCount - 1]
                      }
                      color="blue"
                      flipped={this.state.charFlip}
                    />
                  )}
              </Grid.Column>
              {!this.props.isWidthLessThan979 && (
                <Grid.Column width={2}></Grid.Column>
              )}
            </Grid.Row>
            <Grid.Row textAlign="center">
              <Grid.Column width={2}></Grid.Column>
              {this.state.gameStatus === "NEW" &&
                this.state.playerType === "host" && (
                  <Grid.Column width={12}>
                    <Header as="h2" icon textAlign="center" color="violet">
                      <Header.Content>{this.state.gameShortID}</Header.Content>
                    </Header>
                    <h3>
                      Your game has been created! Other participants can join
                      using the code above. Click the Deal First Cards button
                      once all participants are ready to start! You can also
                      share the link{" "}
                      <a
                        href={`https://myjobisfun.com/tools/team-norming-activity/join-game?${this.state.gameShortID}`}
                      >{`https://myjobisfun.com/tools/team-norming-activity/join-game?${this.state.gameShortID}`}</a>
                    </h3>
                  </Grid.Column>
                )}
              {this.state.gameStatus === "NEW" &&
                this.state.playerType === "guest" && (
                  <Grid.Column width={12}>
                    <Header as="h2" icon textAlign="center" color="violet">
                      <Header.Content>Waiting to Start</Header.Content>
                    </Header>
                    <h3>
                      Congrats, you've joined the game! We're just waiting on
                      your host to deal the cards.
                    </h3>
                  </Grid.Column>
                )}
              <Grid.Column width={2}></Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={2}></Grid.Column>
              <Grid.Column width={8}>
                {this.state.listOfPlayers.length > 0 &&
                  this.state.gameStatus !== "DONE" && (
                    <Grid.Row>
                      <h4>Participants</h4>
                      <PlayersList playerList={this.state.listOfPlayers} />
                    </Grid.Row>
                  )}
              </Grid.Column>
              {this.state.gameStatus === "OPEN" &&
                this.state.hideTimer !== true &&
                this.state.playerType === "host" && <Timer />}
              <Grid.Column width={2}></Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {!this.props.isWidthLessThan979 && (
                <Grid.Column width={5}></Grid.Column>
              )}
              <Grid.Column width={this.props.isWidthLessThan979 ? 16 : 6}>
                {this.state.gameStatus === "DONE" && (
                  <div>
                    <h2>Thanks for playing!</h2>
                    <Button
                      primary
                      onClick={() => {
                        history.push("/tools/team-norming-activity/start-game");
                      }}
                    >
                      Start New Game
                    </Button>
                  </div>
                )}
              </Grid.Column>
              {!this.props.isWidthLessThan979 && (
                <Grid.Column width={5}></Grid.Column>
              )}
            </Grid.Row>
            {this.state.playerType === "host" &&
              this.state.gameStatus === "NEW" && (
                <Button
                  primary
                  loading={
                    this.state.displayLoader &&
                    this.state.characteristicCards.length === 0 &&
                    this.state.situationCards.length === 0
                  }
                  onClick={this.handleDealCards}
                >
                  Deal First Cards
                </Button>
              )}
            {this.state.playerType === "host" &&
              this.state.playCount < 4 &&
              this.state.gameStatus === "OPEN" && (
                <Button
                  primary
                  loading={this.state.displayLoader}
                  onClick={this.handleDealCards}
                >
                  Deal New Cards
                </Button>
              )}
            {this.state.playerType === "host" &&
              this.state.gameStatus === "OPEN" && (
                <Button color="red" onClick={this.handleEndGame}>
                  End Game
                </Button>
              )}
          </Grid>
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  displayLoader: state.displayLoader,
  game: state.game,
});
const mapDispatchToProps = (dispatch) => ({
  setLoader: (value) => dispatch(setLoader(value)),
  setNormingGame: (value) => dispatch(setNormingGame(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewGame);
