import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Grid, Header, Image, Icon, Loader } from "semantic-ui-react";
import YouTubeVideoEmbed from "./YouTubeVideoEmbed";
import history from "./history";
import { combineItems, getVideos } from "../util/video-utility";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter);

let buildPosts = (windowWidth, videos, ideas) => {
  const isWidthLessThan979 = windowWidth < 979;
  const currentIdeas = ideas.filter((idea) =>
    dayjs().isSameOrAfter(idea.published)
  );
  const featuredPosts = combineItems(videos, currentIdeas);

  return featuredPosts.slice(0, 5).map((post, index) => {
    let id;
    if (post.type === "blog") {
      id = post.postId;
    } else {
      id = post.videoId;
    }
    return (
      <Grid.Row stretched key={index}>
        {!isWidthLessThan979 && <Grid.Column width={2}></Grid.Column>}
        <Grid.Column width={isWidthLessThan979 ? 8 : 6}>
          {post.type === "video" ? (
            <YouTubeVideoEmbed videoId={post.videoId} title={post.title} />
          ) : (
            <Image src={post.thumbnail} />
          )}
        </Grid.Column>
        <Grid.Column width={isWidthLessThan979 ? 8 : 6}>
          <a
            href={`ideas/${post.type}?q=${id}`}
            onClick={(event) => handleIdeaClick(event, id, post.type)}
          >
            <Header as="h3">{post.title}</Header>
          </a>
          <p>{post.summary}</p>
          <a
            href={`ideas/${post.type}?q=${id}`}
            onClick={(event) => handleIdeaClick(event, id, post.type)}
          >
            View Post <Icon name="arrow right"></Icon>
          </a>
        </Grid.Column>
        {!isWidthLessThan979 && <Grid.Column width={2}></Grid.Column>}
      </Grid.Row>
    );
  });
};

let handleClick = (e) => {
  e.preventDefault();
  history.push("/ideas");
};

let handleIdeaClick = (e, id, type) => {
  e.preventDefault();
  history.push(`/ideas/${type}?q=${id}`);
};

const FeaturedIdeas = (props) => {
  const [featuredVideos, setFeaturedVideos] = useState([]);
  const [loadingFeaturedVideos, setLoadingFeaturedVideos] = useState(false);
  useEffect(() => {
    setLoadingFeaturedVideos(true);
    const fetchData = async () => {
      const result = await getVideos("PLeYYcuS7MI5K4gB46CvJ5tjsu2s38iQBZ").then(
        (result) => {
          setLoadingFeaturedVideos(false);
          return result;
        }
      );

      setFeaturedVideos(result);
    };
    fetchData();
  }, []);

  return (
    <Grid stackable className="featured-ideas">
      <Grid.Row textAlign="center">
        <Grid.Column>
          <Header as="h2">Bright Ideas</Header>
        </Grid.Column>
      </Grid.Row>
      {loadingFeaturedVideos ? (
        <Loader active inline="centered" size="huge" />
      ) : (
        buildPosts(props.windowWidth, featuredVideos, props.ideas)
      )}
      <Grid.Row centered>
        <Button primary onClick={(event) => handleClick(event)}>
          See more ideas!
        </Button>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  ideas: state.ideas,
});

export default connect(mapStateToProps)(FeaturedIdeas);
