import React, { useState } from "react";
import { Grid } from "semantic-ui-react";

class Timer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minutes: 2,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;
      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    const { minutes, seconds } = this.state;
    return (
      <Grid.Column width={4} className="timer">
        <Grid.Row>
          <h4>Timer</h4>
        </Grid.Row>
        {minutes === 0 && seconds === 0 ? (
          <Grid.Row>
            <h3>Time's Up!</h3>
          </Grid.Row>
        ) : (
          <Grid.Row>
            <h3>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </h3>
          </Grid.Row>
        )}
      </Grid.Column>
    );
  }
}

export default Timer;
