import React from "react";

import { Grid, Header, Image, Icon, Segment, Button } from "semantic-ui-react";
import YouTubeVideoEmbed from "./YouTubeVideoEmbed";

const handleClick = (e, course) => {
	e.preventDefault();
	window.location.assign(`https://courses.myjobisfun.com/${course}`);
};

const FeaturedCourse = (props) => {
	const isWidthLessThan979 = props.windowWidth < 979;
	return (
		<Segment inverted color="yellow">
			<Grid
				stackable
				columns={isWidthLessThan979 ? 1 : 2}
				className="featured-content"
			>
				<Grid.Column>
					<Image src="../images/noun_book_video_sm.png" size="tiny" centered />
					<Header as="h3" textAlign="center">
						Gamification For Learning & Performance
					</Header>
					{isWidthLessThan979 && (
						<YouTubeVideoEmbed
							videoId="O683bg1zbJM"
							title="Gamification For Learning & Performance Overview"
						/>
					)}
					<Header as="h4">Learn</Header>
					<p>
						If you've missed one of our popular gamification classes at
						Stanford, here's your chance to learn about gamification for
						training and learning.
					</p>
					<Header as="h4">Highlights</Header>
					<ul>
						<li>Learn 25+ game mechanics</li>
						<li>1.5+ hours of video tutorials</li>
						<li>5 real-world "boss battle challenges"</li>
						<li>Downloadable tools, templates and references</li>
						<li>
							Examples from our decades of combined experience working at
							companies and teaching at Stanford
						</li>
					</ul>
					<div className="get-started" align="middle">
						<Button
							primary
							size="huge"
							onClick={(e) => handleClick(e, "gamification")}
						>
							Take the Online Course
						</Button>
						<Button
							secondary
							size="huge"
							onClick={(e) => handleClick(e, "gameon")}
						>
							Buy the Book
						</Button>
					</div>
				</Grid.Column>
				{!isWidthLessThan979 && (
					<Grid.Column verticalAlign="middle">
						<YouTubeVideoEmbed videoId="O683bg1zbJM" />
					</Grid.Column>
				)}
			</Grid>
		</Segment>
	);
};

export default FeaturedCourse;
