import React, { useState } from "react";
import {
	Form,
	Input,
	TextArea,
	Button,
	Message,
	Select,
} from "semantic-ui-react";
import { sendProtoboxMessageFunction } from "../util/firebase-functions";

const ProtoboxForm = (props) => {
	const [formError, setFormError] = useState(false);
	const [fullName, setFullName] = useState("");
	const [quantity, setQuantity] = useState("");
	const [emailAddress, setEmailAddress] = useState("");
	const [address, setAddress] = useState("");
	const [loading, setLoading] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [fullNameError, setFullNameError] = useState(false);
	const [quantityError, setQuantityError] = useState(false);
	const [addressError, setAddressError] = useState(false);
	const [apiError, setApiError] = useState(false);
	const [sent, setSent] = useState(false);

	const isEmailAddressValid = (email) => {
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	};

	const handleChange = (event) => {
		this.setState({
			[event.target.id]: [event.target.value],
		});
	};

	const handleChangeName = (event) => {
		setFullNameError(false);
		setFormError(false);
		setFullName(event.target.value);
	};

	const handleChangeEmail = (event) => {
		setEmailError(false);
		setFormError(false);
		console.log(event.target);
		setEmailAddress(event.target.value);
	};

	const handleChangeQuantity = (event) => {
		setQuantityError(false);
		setFormError(false);
		console.log(event.target);
		setQuantity(event.target.value);
	};

	const handleChangeAddress = (event) => {
		setAddressError(false);
		setFormError(false);
		setAddress(event.target.value);
	};

	const handleClick = (event) => {
		setFormError(false);
		let emailErrorInd = emailAddress === "";
		const fullNameErrorInd = fullName === "";
		const addressErrorInd = address === "";
		const quantityErrorInd = quantity === "";

		if (!emailErrorInd) {
			if (!isEmailAddressValid(emailAddress)) {
				emailErrorInd = true;
			}
		}
		if (
			emailErrorInd ||
			fullNameErrorInd ||
			addressErrorInd ||
			quantityErrorInd
		) {
			setFormError(true);
			setLoading(false);
			setApiError(false);
			setSent(false);
			setEmailError(emailErrorInd);
			setFullNameError(fullNameErrorInd);
			setAddressError(addressErrorInd);
			setQuantityError(quantityErrorInd);
		} else {
			setLoading(true);
			setApiError(false);
			setSent(false);
			setEmailError(emailErrorInd);
			setFullNameError(fullNameErrorInd);
			setAddressError(addressErrorInd);
			setQuantityError(quantityErrorInd);

			sendProtoboxMessageFunction({
				email: emailAddress,
				fullName: fullName,
				quantity: quantity,
				address: address,
			})
				.then((response) => {
					setSent(true);
					setLoading(false);
					setFullName("");
					setAddress("");
					setEmailAddress("");
				})
				.catch((error) => {
					setSent(false);
					setLoading(false);
					setApiError(true);
					setFormError(true);
				});
		}
	};

	return (
		<Form loading={loading} error={formError} success={sent}>
			<Form.Field
				id="fullName"
				control={Input}
				label="Full Name"
				placeholder="Janet Doe"
				value={fullName}
				//required
				onChange={handleChangeName}
				className={fullNameError ? "error" : ""}
			/>
			<Form.Field
				id="emailAddress"
				control={Input}
				label="Email address"
				placeholder="j.doe@gmail.com"
				value={emailAddress}
				//required
				onChange={handleChangeEmail}
				className={emailError ? "error" : ""}
			/>
			<Form.Field
				id="quantity"
				control={Input}
				label="Quantity"
				placeholder="10"
				value={quantity}
				//required
				onChange={handleChangeQuantity}
				className={quantityError ? "error" : ""}
			/>
			<Form.Field
				id="address"
				control={TextArea}
				label="Mailing Address"
				placeholder=""
				value={address}
				onChange={handleChangeAddress}
				className={addressError ? "error" : ""}
			/>
			<Button fluid color="violet" onClick={handleClick}>
				Send!
			</Button>
			{emailError || fullNameError || addressError || quantityError ? (
				<Message
					error
					header="Uh oh"
					content="Looks like there might be a typo.  Please correct the highlighted fields and re-submit."
				/>
			) : (
				<Message
					error
					header="Uh oh"
					content="Sorry for the delay but we seem to be having technical issues.  Please email us at Jesse@myjobis.fun"
				/>
			)}
			<Message
				success
				header="Sent!"
				content="Got it!, we'll be in touch with you soon!"
			/>
		</Form>
	);
};

export default ProtoboxForm;
